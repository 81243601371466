import { PrincipalComponent } from './pages/principal/principal.component';
import { ExtraOptions, RouterModule, Routes, CanActivate, Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { PagesResolve } from './pages/pages-resolve';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../services/login.service';
import { Ambiente } from './ambiente/ambiente';
import { environment } from '../environments/environment';

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    resolve: { itemMenu: PagesResolve }
  },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {

  public usuario

  constructor(private cookie: CookieService,
    private loginService: LoginService,
    private router: Router) {

    if (this.cookie.get('Login')) {
      this.usuario = { Login: this.cookie.get('Login'), Senha: atob(this.cookie.get("Senha")), Ambiente: this.cookie.get('Ambiente') }

      if (this.cookie.get('Ambiente') == 'CRMFocus_HOM') {
        environment.emitir('CRMFocus_HOM')
      }

      if (this.cookie.get('Ambiente') == 'CRMFocus') {
        environment.emitir('CRMFocus')
      }

      this.loginService.fazerLogin(this.usuario)
    }
  }
}
