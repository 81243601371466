import { id } from '@swimlane/ngx-charts';
import { url } from 'inspector';
import { Injectable, EventEmitter } from '@angular/core';
import { TokenService } from './token.service';
import { Usuario } from '../models/usuario'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  emitirUsuario = new EventEmitter<any>()

  API = environment.API


  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }


  get(id, token){

    let perfil
    let acesso = {'Authorization': token}

    this.http.get<any>(`${this.API}usuario/${id}`, {headers: acesso, observe: 'body'}).subscribe( resposta => {

      perfil = resposta

      this.emitirUsuario.emit(resposta)
    })

  }

  getPromise(id){
    return this.http.get<any>(`${this.API}usuario/${id}`).toPromise()
  }

  post(usuario, token) : Promise<any>{

    let httpHeader = new HttpHeaders({
      'Authorization': `${token}`
    })

    return this.http.post<any>(`${this.API}usuario`, usuario).toPromise()

  }

  postFoto(arquivo, Id, Site){
    const formData: FormData = new FormData();
    formData.append('Arquivo', arquivo)
    formData.append("Id", Id)
    formData.append('Site', Site)
    return this.http.post<any>(`${this.API}UsuarioFoto`, formData)
  }

  deleteFoto(id){
    const formData: FormData = new FormData();
    formData.append('Id', id)

    return this.http.post<any>(`${this.API}UsuarioFoto/RemoverFoto`, formData)
  }

  delete(id, token) : Promise<any>{

    let httpHeader = new HttpHeaders({
      'Authorization': token
    })

    return this.http.delete(`${this.API}usuario/${id}`).toPromise()

  }

  getTabelaUsuario(token){


    const acesso = `Bearer ${token.token}`


    const httpHeader = new HttpHeaders({
      'Authorization': acesso
    })

    return this.http.get<any>(`${this.API}TabelaPesquisa/Usuario`, {headers: httpHeader})
  }

  pegarTodosUsuarios() : Promise<any>{
    const httpParams = new HttpParams({
      fromObject: {
        tabela: "Usuario",
        coluna: "Nome",
        valor: ""
      }
    })

    return this.http.get<any>(`${this.API}TabelaPesquisa/GetPesquisa`, {params: httpParams}).toPromise()
  }

  fazPesquisaUsuario(token, filtro, componente, campo){


    let acesso = token.token

    const httpHeader = new HttpHeaders({
      'Authorization': `Bearer ${acesso}`
    })

    const httpParams = new HttpParams({
      fromObject: {
        tabela: componente,
        coluna: campo,
        valor: filtro
      }
    })

    return this.http.get<any>(`${this.API}TabelaPesquisa/GetPesquisa`, {headers: httpHeader, params: httpParams})
  }

  fazPesquisaUsuarioPromise(token, filtro, componente, campo) : Promise<any>{

    let acesso = token.token

    const httpHeader = new HttpHeaders({
      'Authorization': `Bearer ${acesso}`
    })


    const httpParams = new HttpParams({
      fromObject: {
        tabela: componente,
        coluna: campo,
        valor: filtro,
      }
    })

    return this.http.get<any>(`${this.API}TabelaPesquisa/GetPesquisa`, {headers: httpHeader, params: httpParams}).toPromise()
  }

  fazPesquisaUsuarioEquipe(filtro, componente, campo, departamento){

    const httpParams = new HttpParams({
      fromObject: {
        tabela: componente,
        coluna: campo,
        valor: filtro,
        condicaoPadrao: departamento
      }
    })

    return this.http.get<any>(`${this.API}TabelaPesquisa/GetPesquisa`, {params: httpParams})
  }



}


