import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventEmitter } from 'events';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment'
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})

export class AgendaService {

  API = environment.API

  public eventoRemovido = new Subject();

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  public PesquisarFeriados(ano) {
    return this.http.get<any[]>(`https://api.calendario.com.br/?json=true&ano=${ano}&&token=dGlAZm9jdXNjb250YWJpbC5jb20maGFzaD0xODIxNDc2MjI`).toPromise()
  }

  public PegarEventos(id, data) {

    const httpParams = new HttpParams({
      fromObject: {
        idUsuario: id,
        Data: data
      }
    })
    return this.http.get<any[]>(`${this.API}Eventos/GetEventos`, { params: httpParams }).toPromise()
  }

  public getTabelaEventos() {
    return this.http.get<any>(`${this.API}TabelaPesquisa/VWEvento`)
  }

  get(id) {
    return this.http.get<any>(`${this.API}Eventos/${id}`)
  }

  public fazPesquisaEventos(campo, componente, filtro) {
    const httpParams = new HttpParams({
      fromObject: {
        tabela: componente,
        coluna: filtro,
        valor: campo
      }
    })

    return this.http.get<any>(`${this.API}TabelaPesquisa/GetPesquisa`, {params: httpParams})
  }

  public AdicionaEvento(evento) {
    return this.http.post<any[]>(`${this.API}Eventos`, [evento]).toPromise()
  }

  public EmiteEventoRemovido(evento) {
    this.eventoRemovido.next(evento)
  }

  public RemoveEvento(evento) {
    return this.http.delete(`${this.API}Eventos/${evento.Id}`).toPromise();
  }

  public gerarRelatorio(parametros){
    return this.http.post(`${this.API}Relatorio/RelatorioAgenda`, parametros, {responseType: 'blob'})
  }
  
}
