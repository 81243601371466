import { HttpClient } from '@angular/common/http';
import { HostListener, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { LoginService } from './login.service';

@Injectable()
export class NotificacaoService {

  API = environment.API
  public ListaNotificacao = [];
  public usuario;

  public novaNotificacaoObservable = new Subject<any>();
  public listaNovasNotificacoesObservable = new Subject<any>();

  constructor(public http: HttpClient,
              public loginService: LoginService) { 

                environment.emitAPI.subscribe(api => {
                  this.API = api
                })

  }

  get(idUsuario){
    return this.http.get<any[]>(`${this.API}Notificacao/${idUsuario}`).toPromise()
  }

  post(listaNotificacao){
    return this.http.post<any>(`${this.API}Notificacao/`, listaNotificacao).toPromise()
  }

  public buscaNotificacoes(){

    this.usuario = this.loginService.getUsuarioDadosCompletos();

    this.get(this.usuario.Id).then(Notificacoes => {
      if (this.ListaNotificacao.length == 0){

        this.ListaNotificacao = Notificacoes
        this.emiteNovaListaNotificacao(this.ListaNotificacao);

      } else {        

        for(let i = 0; i < Notificacoes.length; i++){
          
          if(!this.ListaNotificacao.some(Notificacao => Notificacao.Id === Notificacoes[i].Id)){
            this.ListaNotificacao = Notificacoes
            this.emiteNovaNotificacao(Notificacoes[i])
          }

        }

      }

      
    })
  }

  public emiteNovaNotificacao(Notificacao){
    this.novaNotificacaoObservable.next(Notificacao)
  }

  public emiteNovaListaNotificacao(ListaNotificacao){
    this.listaNovasNotificacoesObservable.next(ListaNotificacao)
  }


}
