<div #mainWrapper [ngStyle]="{'background-color': (flagHomologacao) ? '#ffffff' : '#ffffff'}"
  style="display: flex; justify-content: space-between; width: 100%;">

  <div class="header-container">
    <div class="logo-container">
      <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
        <nb-icon icon="menu-2-outline"></nb-icon>
      </a>
      <div *ngIf="flagHomologacao" class="avisoHomologacao">
        <b>AMBIENTE DE HOMOLOGAÇÃO</b>
      </div>
    </div>

  </div>

  <div class="header-container">
    <nb-actions size="small">

      <nb-action class="control-item">
      </nb-action>

      


      <nb-action class="header-dimension">

        <button *ngIf="HabilitaChat == true" #tooltip="matTooltip" matTooltip="Fila de Atendimento" mat-icon-button color="basic"
        (click)="toogleSidebarRight()"><mat-icon matBadgeColor="warn" matBadge="{{numeroClientesFila}}"><svg
            height="100%" style="vertical-align: top;" id="svg8" version="1.1" viewBox="0 0 33.866666 33.866668"
            width="100%" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#"
            xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:svg="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs id="defs2">
              <linearGradient id="linearGradient2202">
                <stop id="stop2198" offset="0" style="stop-color:#fafafa;stop-opacity:1;" />
                <stop id="stop2200" offset="1" style="stop-color:#f69134;stop-opacity:1" />
              </linearGradient>
              <linearGradient gradientTransform="matrix(0.39352029,0,0,0.39352035,-185.27183,93.82285)"
                gradientUnits="userSpaceOnUse" id="linearGradient3363" x1="12.262755" x2="12.262755"
                xlink:href="#linearGradient2202" y1="9.0164375" y2="14.680936" />
              <clipPath clipPathUnits="userSpaceOnUse" id="clipPath3457">
                <rect height="58.93293" id="rect3459" rx="2.6458333" ry="2.6458333"
                  style="fill:#1a1a1a;stroke-width:0.264583;stroke-linecap:square;stroke-dasharray:0.264583, 1.05833;paint-order:fill markers stroke"
                  width="61.739258" x="-262.11121" y="114.49826" />
              </clipPath>
            </defs>
            <g id="layer1">
              <path
                d="M 16.93556,1.0583333 C 8.1694332,1.0585159 1.0632121,8.1649784 1.0633273,16.931106 c 1.05e-4,2.882377 0.7850851,5.710321 2.2707003,8.18035 l -0.8496093,5.758465 c -0.2210501,1.410763 0.9090354,2.103084 1.9625067,1.906374 l 6.436493,-1.172806 c 1.919478,0.791969 3.9757,1.19962 6.052142,1.199851 8.766128,1.16e-4 15.872591,-7.106107 15.872773,-15.872234 C 32.808448,8.1647677 25.701898,1.058218 16.93556,1.0583333 Z"
                id="path4160"
                style="fill:#59d96b;fill-opacity:1;stroke:none;stroke-width:2.64543;stroke-linecap:square;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:fill markers stroke" />
              <path
                d="M 12.476514,8.7612452 C 11.469814,7.7849473 10.057388,9.0119609 9.4642537,9.6688596 8.1854926,11.08509 7.6950735,13.213983 8.3256377,15.294078 c 0.6305448,2.080035 2.1636993,4.173481 4.6984823,6.462259 2.534823,2.288735 4.773407,3.600912 6.906807,4.016534 2.13346,0.415633 4.201403,-0.288867 5.480162,-1.705095 0.593137,-0.656898 1.67006,-2.186845 0.596398,-3.088978 -0.07154,-0.06018 -0.152818,-0.11739 -0.244291,-0.171503 l -3.560497,-1.133098 c -0.596005,-0.242929 -1.105761,0.08722 -1.504348,0.451026 l -1.770333,1.615925 C 17.928749,21.232581 16.724431,20.409844 15.355755,19.17405 13.987104,17.938223 13.046094,16.823876 12.43849,15.881546 l 1.4274,-1.925572 c 0.321354,-0.433539 0.597925,-0.974243 0.295611,-1.542434 L 12.671987,8.9868156 C 12.608848,8.9013216 12.543629,8.8263313 12.476516,8.7612442 Z"
                id="path4214"
                style="color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.525946;stroke-linecap:square;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:fill markers stroke;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate;stop-color:#000000;stop-opacity:1" />
            </g>
          </svg></mat-icon></button>

        <button *ngIf="permissaoRegistroVisita.Permissao > 0" #tooltip="matTooltip"
          matTooltip="Registro de visita comercial" mat-icon-button color="basic"
          (click)="abrirRegistroDeVisitaComercial()">
          <mat-icon>add_location</mat-icon>
        </button>

        <button #tooltip="matTooltip" matTooltip="Controle de Solicitações" mat-icon-button color="basic"
          (click)="abrirControleSolicitacao()">
          <mat-icon>library_books</mat-icon>
        </button>

        <button #tooltip="matTooltip" matTooltip="Abrir nova solicitação" mat-icon-button color="basic"
          (click)="abrirDialogSolicitacao()">
          <mat-icon>library_add</mat-icon>
        </button>

        <!-- <button #tooltip="matTooltip" matTooltip="Buscar notificações" mat-icon-button color="basic"
          [disabled]="DisableBotaoRefresh" (click)="BuscarNotificacoes()" style="margin-right: 10px; cursor: pointer">
          <mat-icon>cached</mat-icon>
        </button> -->

        <button *ngIf="ListaNotificacaoMatch.length > 0" mat-icon-button (click)="abrirDialogMatch()"
          style="outline-width: 0;position: relative">
          <div id="wrapper" matBadge="{{ListaNotificacaoMatch.length}}" class="notificacaoBadge" matBadgeSize="small"
            matBadgePosition="after">
            <div id="pulsingheart"></div>
          </div>
        </button>
        <button *ngIf="ListaNotificacaoMatch.length == 0" mat-icon-button disabled>
          <mat-icon>favorite</mat-icon>
        </button>

        <button *ngIf="ListaNotificacao.length > 0" mat-icon-button>
          <mat-icon style="cursor: pointer" matBadge="{{ListaNotificacao.length}}" matBadgeColor="warn" color="basic"
            mat-button [matMenuTriggerFor]="menu">add_alert</mat-icon>
        </button>
        <button *ngIf="ListaNotificacao.length == 0" mat-icon-button disabled>
          <mat-icon style="cursor: pointer" matBadgeColor="warn" mat-button>add_alert</mat-icon>
        </button>
      </nb-action>

      <mat-menu xPosition="after" #menu="matMenu" style="width: 100px;">

        <button *ngIf="ListaNotificacao.length > 1" mat-menu-item>
          <section class="example-section">
            <mat-checkbox (change)="MarcarTodos()" (click)="$event.stopPropagation()" class="example-margin">
              <p style="font-size: 12px; margin-top: 10px;">MARCAR TODOS</p>
            </mat-checkbox>
          </section>
        </button>


        <button *ngFor="let Notificacao of ListaNotificacao; let i = index" (click)="abrirNotificacao(Notificacao)"
          mat-menu-item>
          <mat-checkbox style="display: none;" [(ngModel)]="Notificacao.Checked" class="example-margin">
          </mat-checkbox>
          <div style=" white-space: pre-wrap !important; max-height: 170px; word-wrap: break-all">
            <p style="font-size: 12px; margin-top: 5px;"> {{Notificacao.Descricao}} </p>
          </div>

        </button>

        <button *ngIf="ListaNotificacao.length > 0" (click)="MarcarComoLido()" style="width: 100%; height: 40px;"
          mat-raised-button color="primary">Marcar como visto</button>

      </mat-menu>
      <nb-action class="user-action" *ngIf="innerWidth > 900 else MatUser">
        <nb-user [nbContextMenu]="user ? userMenu : null" [onlyPicture]="userPictureOnly" [name]="user.user"
          [picture]="user?.picture" nbContextMenuTag="menu">

        </nb-user>
      </nb-action>
    </nb-actions>
  </div>
</div>

<ng-template #MatUser>
  <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>person</mat-icon></button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="alterarSenha()">Alterar Senha</button>
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>
</ng-template>