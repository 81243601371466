<div style="width: 100%; height: 100%; overflow: hidden;">
  <div class="fila-Atendimento" [class.reduzir-height]="showListaContato || showAssunto">

    <mat-card-title class="card-title-font">
      Fila de Atendimento - {{EquipeFila[0].Departamento}}
    </mat-card-title>

    <!-- <form [formGroup]="formFila">
  <div class="row d-flex justify-content-start" style="width: 100%">
    <mat-form-field>
      <mat-label>Nº de Clientes</mat-label>
      <input matInput formControlName="NClientes" readonly type="text">
    </mat-form-field>
  </div>

</form> -->

    <table mat-table [dataSource]="dataSourceFila" multiTemplateDataRows class="fila-table"
      *ngIf="FilaAtendimento.state == 'Connected'">

      <ng-container matColumnDef="statusBolinha">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.Status == 'Em espera'" class="green" fontIcon="check_circle">circle</mat-icon>
          <mat-icon *ngIf="element.Status == 'Em atendimento'" class="red" fontIcon="check_circle">circle</mat-icon>
          <mat-icon *ngIf="element.Status == 'Transferência em espera'" class="yellow" fontIcon="check_circle">circle</mat-icon>
        </td>
      </ng-container>

      <ng-container *ngFor="let coluna of colunasFila.Valor; let i = index" matColumnDef="{{coluna}}">
        <th mat-header-cell *matHeaderCellDef> {{colunasFila.Descricao[i]}} </th>
        <td mat-cell *matCellDef="let element">{{element[coluna]}}</td>
      </ng-container>

      <ng-container matColumnDef="Cronometro">
        <th mat-header-cell *matHeaderCellDef>Tempo</th>
        <td mat-cell *matCellDef="let element">{{element["Cronometro"]}} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="4">
      <div class="example-element-detail" *ngIf="expandedElement && expandedElement === element"
        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <button (click)="iniciarConexaoChat(element)">teste</button>
      </div>
    </td>
  </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="ColunasFila.Valor"></tr>
      <tr mat-row (click)="AbrirPaginaPergunta(element)" *matRowDef="let element; columns: ColunasFila.Valor"
        class="example-element-row" [class.example-expanded-row]="expandedElement === element"
        [matTooltip]="formataTooltip(element.UsuarioAtendente)"></tr>
      <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
    </table>

    <div class="d-flex justify-content-center" *ngIf="FilaAtendimento.state == 'Reconnecting'">
      <br>
      <h6>Conexão instável... Reconectando...</h6>
      <mat-spinner disabled [diameter]="50" style="margin-left: 3px" class="mat-spinner-color"></mat-spinner>
    </div>

    <div class="d-flex justify-content-center" *ngIf="FilaAtendimento.state == 'Disconnected'">
      <br>
      <h6>Não foi possível reestabelecer a conexão, verifique a rede.</h6>
    </div>
    <div class="botaoListaContato">
      <button color="contact" matTooltip="Lista de contatos" mat-icon-button (click)="abrirListaContato()">
        <mat-icon>chat</mat-icon>
      </button>
    </div>
  </div>

  <form [formGroup]="formularioContato">

    <mat-card style="box-shadow: none;" class="mat-elevation-z0 listaContato"
      [ngClass]="{'opened': showListaContato, 'closing': isClosing}">
      <mat-divider></mat-divider>
      <div class="d-flex justify-content-between">
        <mat-card-title style="margin-top: 10px;" class="card-title-font">
          Lista de contatos
        </mat-card-title>

        <button mat-icon-button (click)="abrirListaContato()"><mat-icon>close</mat-icon></button>
      </div>
      <div class="row justify-content-center">
        <mat-form-field style="width: 80%; font-size: 14px;">
          <mat-label>Pesquisar cliente</mat-label>
          <input matInput type="text" formControlName="Cliente">
          <button matSuffix mat-icon-button aria-label="Clear"
            (click)="BuscarCliente()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
              <path fill-rule="evenodd"
                d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
            </svg>
          </button>

          <!-- <button *ngIf="formularioContato.value.idCliente != null" (click)="limparCampos()" mat-button matSuffix
            mat-icon-button aria-label="Clear">
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button> -->
        </mat-form-field>
      </div>

      <div class="row justify-content-center">
        <mat-form-field style="width: 80%; font-size: 14px;">
          <mat-label>Selecionar contato</mat-label>
          <input type="text" formControlName="Contato" matInput
            [disabled]="formularioContato.value.idCliente == null" [matAutocomplete]="auto">
          <mat-autocomplete (optionSelected)="selecionaContato($event)" autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filtrarContatos"
              [value]="option" [displayWith]="option.Contato + ' - ' + option.Telefone">{{option.Contato}} -
              {{option.Telefone}}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <br>
      <div class="d-flex align-bottom justify-content-center">
        <div style="width: 15px;"></div>
        <button mat-raised-button color="green" (click)="iniciarConversa()"
          [disabled]="formularioContato.value.Cliente == null || formularioContato.value.Cliente == '' || formularioContato.value.Contato == null || formularioContato.value.Contato == ''">
          <mat-icon>chat</mat-icon>
          Enviar mensagem
        </button>
      </div>
    </mat-card>

    <mat-card style="box-shadow: none;" class="mat-elevation-z0 listaContato"
      [ngClass]="{'opened': showAssunto, 'closing': isClosingAssunto}">
      <div class="flex-column">
        <div class="row" style="font-size: 13px;">
          <b>Cliente:</b>&nbsp; {{formularioContato.value.Cliente}}
        </div>

        <div class="row" style="font-size: 13px;">
          <b>Contato:</b>&nbsp; {{formularioContato.value.Contato}}

        </div>
        <br>
        <mat-form-field appearance="outline" style="width: 90%;">
          <mat-label>
            Assunto
          </mat-label>
          <textarea matInput style="width: 100%; height: 100%;" formControlName="Assunto" placeholder="Escreva de forma resumida o assunto."></textarea>
        </mat-form-field>
      </div>
      <div class="row justify-content-center">
        <button mat-raised-button (click)="fecharIniciarConversa()" color="warn" style="margin-right: 12px;">Voltar</button>
      <button mat-raised-button (click)="enviaPrimeiraMensagem()" color="send">Enviar<mat-icon style="margin-left: 8px;" >send</mat-icon></button>
      </div>
      
    </mat-card>
  </form>

</div>