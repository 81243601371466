import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ngx-pagina-erro',
  styleUrls: ['pagina-erro.component.scss'],
  templateUrl: 'pagina-erro.component.html',
})


export class PaginaErroComponent {
  displayedColumns = ['TipoMensagem', 'DescricaoErro'];
  dataSource = [];
  isCollapsed = true;

  codigo: string;
  menssagemComplementar: string
  public mensagemEscolhida = null

  constructor(@Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<PaginaErroComponent>) {


    if (data.tipo == "erro") {
      this.dataSource = [
        {
          Codigo: data.mensagem.status,
          Descricao: data.mensagem.name,
          MensagemComplementar: data.mensagem.message,
          TipoMensagem: 2
        }
      ]
    }

    if (data.tipo == "sistema") {


      if (data.mensagem.length) {


        data.mensagem.map(mensagem => mensagem.Codigo).forEach((codigo, i) => {

          if (this.dataSource.length == 0) {
            this.dataSource.push(data.mensagem[i])
          } else {
            if (!this.dataSource.map(mensagem => mensagem.Codigo).includes(codigo)) {
              this.dataSource.push(data.mensagem[i])
            }
          }
          this.escolherMensagem(this.dataSource[0])

        })

        if (this.dataSource.filter(mensagem => mensagem.Codigo != 'CRM00001' && mensagem.Codigo != 'CRM00003').length > 0) {
          this.dataSource = this.dataSource.filter(mensagem => mensagem.Codigo != 'CRM00001' && mensagem.Codigo != 'CRM00003')
        }

      } else {
        this.dataSource = [data.mensagem]
      }
    }

    if (data.tipo == "interno") {

      if (typeof (data.mensagem) == "string") {
        this.dataSource = [
          {
            Codigo: "-",
            Descricao: data.mensagem,
            MensagemComplementar: "",
            TipoMensagem: 1
          }
        ]
      } else {

        this.dataSource = data.mensagem.map(mensagem => {

          return {
            Codigo: "-",
            Descricao: mensagem,
            MensagemComplementar: "",
            TipoMensagem: 1
          }

        })

      }


    }

  }

  verMais(element) {
    this.codigo = element.codigo;
    this.menssagemComplementar = element.menssagemComplementar
  }

  fecharJanela() {
    this.dialogRef.close();
  }

  public escolherMensagem(mensagemEscolhida) {
    if (mensagemEscolhida.MensagemComplementar) {
      this.mensagemEscolhida = mensagemEscolhida
    }
  }

  public fecharMensagem() {
    this.mensagemEscolhida = null
  }

}






