import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbChatFormComponent } from '@nebular/theme';
import { ChatService } from '../../../../../services/chat.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-chat-form-extends',
  template: `<div class="dropped-files" *ngIf="droppedFiles?.length">
  <ng-container *ngFor="let file of droppedFiles">
    <div *ngIf="file.urlStyle" [style.background-image]="file.urlStyle">
      <span class="remove" (click)="removeFile(file)">&times;</span>
    </div>

    <div *ngIf="!file.urlStyle">
      <nb-icon icon="file-text-outline" pack="nebular-essentials"></nb-icon>
      <span class="remove" (click)="removeFile(file)">&times;</span>
    </div>
  </ng-container>
</div>
<div class="message-row">
<input  type="file" [id]="'CaixaArquivos' + Conexao.Telefone" accept=".pdf, .xlsx, .xls, .txt, .csv, .docx" lang="pt" multiple (change)="ArquivosSelecionados($event.target.files)" hidden>

  <button class="anexarDoc" [disabled]="enviandoAnexo" (click)="AbrirCaixaArquivos()" matTooltip="Anexar"><mat-icon style="transform: rotate(90deg);" *ngIf="enviandoAnexo == false">attachment</mat-icon> <mat-spinner *ngIf="enviandoAnexo == true" [diameter]="24"></mat-spinner></button>
  <input style="80%"
    nbInput
    fullWidth
    (focus)="inputFocus = true"
    (blur)="inputFocus = false"
    (mouseenter)="inputHover = true"
    (mouseleave)="inputHover = false"
    [(ngModel)]="message"
    (ngModelChange)="onModelChange($event)"
    [class.with-button]="showButton"
    type="text"
    placeholder="{{ fileOver ? dropFilePlaceholder : messagePlaceholder }}"
    (keyup.enter)="sendMessage()"
  />
  <button
    nbButton
    [status]="getButtonStatus()"
    *ngIf="showButton"
    [class.with-icon]="!buttonTitle"
    (click)="sendMessage()"
    class="send-button"
  >
    <mat-icon>send</mat-icon>
    <ng-template #title>{{ buttonTitle }}</ng-template>
  </button>
</div>`,
  styleUrls: ['./chat-form-extends.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatFormExtendsComponent extends NbChatFormComponent implements OnInit {

  constructor(public chatService: ChatService,
    cd: ChangeDetectorRef,
    domSanitizer: DomSanitizer
  ) {
    super(cd, domSanitizer);
  }

  @Output() onInputChange = new EventEmitter<string>();
  @Output('enviarAnexo') enviarAnexo = new EventEmitter<any>();
  @Input('Conexao') Conexao
  public enviandoAnexo: boolean = false

  ngOnInit(): void {
    this.chatService.enviandoArquivo$.subscribe(bool => {
      this.enviandoAnexo = bool
      this.cd.detectChanges();
    })
  }

  onModelChange(value: string): void {
    this.onInputChange.emit(value);
  }

  AbrirCaixaArquivos() {
    document.getElementById("CaixaArquivos" + this.Conexao.Telefone).click();
  }

  async ArquivosSelecionados(arquivos: FileList) {
    if (arquivos.length > 0) {
      this.chatService.statusEnviandoArquivo(true);
      let listaArquivos = { files: [] }

      for (let i = 0; i < arquivos.length; i++) {
        listaArquivos.files.push(arquivos.item(i))
      }

      this.enviarAnexo.emit(listaArquivos)
    }
  }
}
