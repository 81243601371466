import { Component, Inject } from '@angular/core';

import { PesquisaService } from '../../../../services/pesquisa.service';
import { TokenService } from '../../../../services/token.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormValidations } from '../../../../services/form-validations'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ngx-pesquisa',
  templateUrl: './pesquisa.component.html',
  styleUrls: ['./pesquisa.component.scss']
})

export class PesquisaComponent {

  public formulario: FormGroup;

  public TipoDado = "text"

  public pesquisaRealizada = false
  public carregando = false;

  // CAMPOS QUE SERÃO IMPRESSOS NA LISTA
  public CamposCombobox = [];

  public resultadosPesquisa: Object[];
  public colunas;

  public componente: any;

  public condicao = {}
  public condicaoQuery

  constructor(@Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<PesquisaComponent>,
    private pesquisaService: PesquisaService,
    private tokenService: TokenService,
    private formBuilder: FormBuilder) {


    this.formulario = this.formBuilder.group({
      Campo: [null, [Validators.required]],
      Filtro: [""]
    })

    this.AdicionaInformacoesComponenteFilho(data)

    // INSERE OS CAMPOS NO COMBOBOX RELATIVO AO COMPONENTE
    this.PreencheCombobox()

  }

  // RETORNA TODOS OS OBJETOS OBTIDOS E CRIA UMA COLUNA CHAMADA DESCRICAO EM CADA COM O VALOR QUE SERÁ OBSERVAVEL NO SELECT
  public Pesquisar() {

    this.carregando = true
    this.pesquisaRealizada = true


    this.tokenService.getToken().subscribe(token => {
      this.pesquisaService.getPesquisar(this.formulario.get('Campo').value, this.formulario.get('Filtro').value, this.componente, token, this.condicao, this.condicaoQuery)
        .subscribe(


          response => {


            this.carregando = false

            this.resultadosPesquisa = response
            this.OrdenaResultadosPesquisa();

            // CRIA A COLUNA DESCRICAO COM E INSERE O DADO QUE SERÁ VISTO NO SELECT
            this.colunas = this.pesquisaService.getCampoDaLista(this.componente)

          }
        )
    })


  }

  public VoltarPesquisa() {
    this.pesquisaRealizada = false
  }

  // ENVIA PARA O SERVIÇO O ITEM SOLICITADO E DELETA A COLUNA DESCRICAO
  public EnviaItemTabela(obj) {
    this.pesquisaService.recebeItemAchado(obj, this.componente);
    this.dialogRef.close(obj);
  }

  public PreencheCombobox() {

    this.tokenService.getToken().subscribe(token => {
      this.pesquisaService.getCombobox(this.componente, token).subscribe(dados => {

        dados[0].ListaCampos.forEach(item => {
          // if (item.Coluna != "Id")
          this.CamposCombobox.push(item)
        })

        this.formulario.patchValue({
          Campo: this.CamposCombobox[0].Coluna
        })

        this.TipoDado = this.CamposCombobox[0].TipoDado

      })

    })

  }

  public OrdenaResultadosPesquisa() {

    this.resultadosPesquisa.sort((a: any, b: any) => {

      if (a.Id > b.Id) {
        return 1;
      }

      if (a.Id < b.Id) {
        return -1;
      }

      return 0;

    });
  }

  public AdicionaInformacoesComponenteFilho(data) {

    this.componente = data.componente

    if (data.idDepartamento) {
      this.condicao['idDepartamento'] = `idDepartamento=${data.idDepartamento}`
    }

    if (data.mostrarBloqueados) {
      this.condicao['mostrarBloqueados'] = true
    }

    if (data.naoMostrarInativos) {
      this.condicao['naoMostrarInativos'] = true
    }

    if (data.condicaoQuery) {
      this.condicaoQuery = data.condicaoQuery
    }
  }

  public MudaTipoCampo(evento) {

    this.CamposCombobox.forEach((cp: any) => {

      if (cp.Coluna == evento) {
        this.formulario.patchValue({
          Filtro: ""
        })
        this.TipoDado = cp.TipoDado
      }

    })
  }


}
