import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteProspectsService {

  API = environment.API;
  public DetalheId = 0;
  public PesquisaFiltro = null
  public Voltar: boolean = false

  public VoltaProspect: boolean = false


  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  public VoltaControleProspect (corpo?) {

    if (corpo) {
      this.Voltar = true
    }
    else {
      this.Voltar = false
    }
  }

  public HabilitarVoltaProspect(condicao) {
    this.VoltaProspect = condicao
  }


  public DetalheProspect (Id?: number, formulario?){
    if (Id) {
      this.PesquisaFiltro = formulario
      this.DetalheId = Id
    }

    else {

      return this.PesquisaFiltro

    }
  }


  public PesquisaControleProspect(corpo: any): Promise<any> {
    return this.http.post(`${this.API}ConsultaProspect`, corpo).toPromise();
  }

  public getClienteProspectsIndividual(id: Number): Promise<any> {
    return this.http.get(`${this.API}ClienteProspect/${id}`).toPromise();
  }

  public getClienteProspects() {
    return this.http.get(`${this.API}TabelaPesquisa/VWContatoClienteProspect`);
  }

  public postClienteProspects(corpo: any): Promise<any> {
    return this.http.post(`${this.API}ClienteProspect`, corpo).toPromise();
  }

  public deleteClienteProspects(id: Number): Promise<any> {
    return this.http.delete(`${this.API}ClienteProspect/${id}`).toPromise();
  }

  public alteraResponsavel(idProspect, idUsuario) {
    return this.http.get(`${this.API}ConsultaProspect?idProspect=${idProspect}&idUsuario=${idUsuario}`)
  }
  public getTodosClienteProspect(){
    return this.http.get(`${this.API}ClienteProspect/GetTodosClienteProspect`).toPromise();
  }

  public PesquisaClienteProspects(filtro, componente, campo, condicao?) {

    let HTTP_PARAMS

    if(condicao){
      HTTP_PARAMS = new HttpParams({
        fromObject: {
          tabela: componente,
          coluna: campo,
          valor: filtro,
          condicaoPadrao: condicao
        }
      });
    } else {
      HTTP_PARAMS = new HttpParams({
        fromObject: {
          tabela: componente,
          coluna: campo,
          valor: filtro
        }
      });
    }


    return this.http.get(`${this.API}TabelaPesquisa/GetPesquisa`, { params: HTTP_PARAMS });
  }

  postRegistroVisita(body){
    return this.http.post(`${this.API}ClienteProspect/PostRegistroVisita`, body)
  }

  postRegistroVisitaProspect(body){
    return this.http.post(`${this.API}RegistroVisita/PostRegistroVisitaProspect`, body)
  }

  getRegistroVisitaProspect(id){
    return this.http.get(`${this.API}RegistroVisita/GetRegistroVisitaProspect/${id}`)
  }

  postRegistroVisitaCliente(body){
    return this.http.post(`${this.API}RegistroVisita/PostRegistroVisitaCliente`, body)
  }

  getRegistroVisitaCliente(id: number){
    return this.http.get(`${this.API}RegistroVisita/GetRegistroVisitaCliente/${id}`)
  }

  getAgendaVisitaProspect(id){
    return this.http.get(`${this.API}ClienteProspect/GetRegistroVisitaAgenda/${id}`)
  }

}
