import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { EquipeService } from '../../../../services/equipe.service';
import { ChatService } from '../../../../services/chat.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar state="compacted" class="menu-sidebar" tag="menu-sidebar" responsive>
        <div class="main-container">
        <ng-content select="nb-menu"></ng-content>
        </div>
      </nb-sidebar>

      <nb-sidebar *ngIf="Equipe.length != 0" #filaSide class="fila-sidebar" right state="collapsed" fixed tag="fila-sidebar-right" responsive>
        <app-fila-atendimento [EquipeFila]="Equipe"></app-fila-atendimento>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent  implements OnDestroy {
  
  @ViewChild('filaSide') filaSide
  Equipe: any = []
  
  constructor(private sidebarService: NbSidebarService,
    private equipeService: EquipeService,
    private chatService: ChatService
  ){
    this.equipeService.getEquipeFila()
      .toPromise()
      .then(response => {
        this.Equipe = response
        if(this.Equipe.length > 0){
          this.chatService.emitirHabilitaChat(this.Equipe)
        }
      })
  }

  ngOnDestroy(): void {
    this.chatService.emitirHabilitaChat([])
  }
}
