import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { database } from 'firebase';

@Component({
  selector: 'ngx-gerador-pdf',
  templateUrl: './gerador-pdf.component.html',
  styleUrls: ['./gerador-pdf.component.scss']
})
export class GeradorPdfComponent implements OnInit, AfterViewInit {

  @ViewChild('pdfViewer') public pdfViewer;
  
  public ArquivoPDF
  public NomeArquivo

  public MensagemErro: String = "Erro ao carregar o PDF, por favor tente novamente!";

  constructor(@Inject(MAT_DIALOG_DATA) data,
  public dialog: MatDialogRef<GeradorPdfComponent>) 
  { 
    this.ArquivoPDF = data.ArquivoPDF,
    this.NomeArquivo = data.NomeArquivo
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.pdfViewer.pdfSrc = this.ArquivoPDF; // pdfSrc can be Blob or Uint8Array
    this.pdfViewer.refresh(); // Ask pdf viewer to load/refresh pdf
  }
  fecharDialog() {
    this.dialog.close() 
  }
}
