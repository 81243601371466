import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private API

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  static loginTOKEN
  static nomeUsuario

  /* 
  
  HAVERÁ UM MÉTODO POST NO QUAL DEVERÁ SER ENVIADO LOGIN E SENHA PARA UM LINK E ESTE ME RETORNARÁ
  UM JSON COM AS PERMISSÕES DO USUÁRIO, ESTE JSON TERÁ 3 CAMPOS, CDPERFIL, DSPERFIL E PERMISSAO

  COLOCAR NA OPÇÃO DE MENU LATERAL O CAMPO PERMISSÃO, TODA VEZ QUE FOR ACESSADO UM COMPONENTE
  DEVERÁ SER CONSULTADO ESTE CAMPO PARA RENDERIZAR A PÁGINA DE ACORDO COM A PERMISSÃO RECEBIDA
  
  */

  setLoginToken(login){
    TokenService.loginTOKEN = login
  }

  getLoginToken(){
    return TokenService.loginTOKEN
  }

  getNomeUsuario(){
    return TokenService.nomeUsuario
  }

  setNomeUsuario(nome){
    TokenService.nomeUsuario = nome
  }

  getTokenBearer(){

    this.http.post<any>(`${this.API}account/login`, TokenService.loginTOKEN)
      .subscribe(resposta => {
        
        let token = resposta.token
        let tokenBearer = `Bearer ${token}`

         return tokenBearer

      })   
     
  }

  getToken(){
    return this.http.post<any>(`${this.API}account/login`, TokenService.loginTOKEN)   
  }

  getTokenPromise() : Promise<any>{
    return this.http.post<any>(`${this.API}account/login`, TokenService.loginTOKEN).toPromise() 
  }

  async estaAutenticado(){
    
    let valor

    await this.getTokenPromise()
      .then(
        response => {
          if (response.token){
            TokenService.nomeUsuario = response.user
            valor = true
          }
        }      
      )      
      .catch(response => {
        valor = false
      })

    return valor
  }

  // MÉTODO PARA TRANFORMAR O TOKEN ADQUIRIDO EM UM TOKEN VÁLIDO

  /* HAVERÁ UMA LÓGICA PARA DETERMINAR A ROTINA QUE SEBERÁ OS CAMPOS, EX: SE O CDFUNCAO > 4000
  RETORNARÁ AS ROTINAS DE OPERAÇOES, ENTRE 1000 E 2000 RETORNARÁ AS ROTINAS DE SEGURANÇA
  
  É NECESSÁRIO PARA QUE NÃO HAJA MÉTODOS DIFERENTES PARA O MESMO PROÓSITO 
  */
  getTokenHeader(token){
    return `Bearer ${token.token}`
  }

  static retornaPermissoesSeguranca(){
    
    return [{
      title: 'Usuário',
      link: '/pages/seguranca/usuario',
      permissao: 1
    }, 
    {
      title: 'Perfil de acesso',
      link: '/pages/seguranca/perfilacesso',
      permissao: 2
    }]

  }
}
