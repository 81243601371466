import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AtendimentoSolicitacaoService } from '../../../../../services/atendimento-solicitacao.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HubConnection } from '@microsoft/signalr';
import { EquipeService } from '../../../../../services/equipe.service';

@Component({
  selector: 'app-troca-fila-chat',
  templateUrl: './troca-fila-chat.component.html',
})
export class TrocaFilaChatComponent implements OnInit {

  dados
  SelectEquipes = []
  formTroca: FormGroup

  constructor(@Inject(MAT_DIALOG_DATA) data,
    public atendimentoSolicitacaoService: AtendimentoSolicitacaoService,
    public formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<TrocaFilaChatComponent>,
    private equipeService: EquipeService) {

    this.formTroca = formBuilder.group({
      novaEquipe: []
    })
    this.dados = data
    this.equipeService.getEquipeChat()
      .toPromise()
      .then(response => {
        this.SelectEquipes = response
        // response.forEach(item => {
        //   if(item.idOpcaoApp != null){
        //     this.SelectDepartamento.push(item)
        //   }
        // })
        // this.SelectDepartamento.unshift('')
      })
  }

  ngOnInit(): void {
  }

  async confirmaTroca() {
    let hubFila: HubConnection = this.dados.connectionFila
    let hubChat: HubConnection = this.dados.connectionChat
    await hubFila.send("SetAlteraFilaAtendimento", this.dados.idUserChat,
      this.dados.antigoGrupoName,
      this.formTroca.value.novaEquipe.idEquipe.toString())
      .then(response => {

        this.matDialogRef.close(this.formTroca.value.novaEquipe);
      })

  }
}
