<div mat-dialog-title style="color: whitesmoke;">{{solicitante.NomeUsuarioWpp}}</div>
<mat-dialog-content>
  <app-chat-extends *ngIf="conversa.length > 0">
    <app-chat-message-extends *ngFor="let msg of conversa" class="message" [type]="msg.type"
          [message]="msg.text" [reply]="msg.reply" [sender]="msg.user.name" [date]="msg.date" [files]="msg.files"
          [quote]="msg.quote" [customMessage]="msg.customMessage" [avatar]="avatar"></app-chat-message-extends>
  </app-chat-extends>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button style="margin-top: 5px;" mat-dialog-close color="return">Voltar</button>
</mat-dialog-actions>