import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BriefingService {

  public API = environment.API

  public listaCNPJexistentes = []

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  public getTabelaBriefing() {

    return this.http.get(`${this.API}TabelaPesquisa/VWBriefing`)
  }

  public fazPesquisaBriefing(campo, component, filtro) {

    const httpParams = new HttpParams({

      fromObject: {
        tabela: component,
        coluna: filtro,
        valor: campo,
      }
    })

    return this.http.get(`${this.API}TabelaPesquisa/GetPesquisa`, { params: httpParams })

  }

  public post(formulario) {
    return this.http.post<any>(`${this.API}Briefing`, formulario)
  }

  public delete(id) {

    return this.http.delete(`${this.API}Briefing/${id}`)
  }

  public get(id) {

    return this.http.get<any>(`${this.API}Briefing/${id}`)
  }


  public PDFBriefingFinanceiro(corpo): Observable<Blob> {

    return this.http.post(`${this.API}Relatorio/RelatorioBriefingFinanceiro`, corpo, { responseType: 'blob' })
  }

  public PDFBriefing(corpo): Observable<Blob> {

    return this.http.post(`${this.API}Relatorio/RelatorioBriefing`, corpo, { responseType: 'blob' })
  }


  public getUltimoBriefing() {
    return this.http.get<number>(`${this.API}Briefing/GetUltimoBriefing`)
  }

  public upLoadArquivoBriefing(arquivo){

    const formData = new FormData();
    formData.append('Arquivo', arquivo)

    return this.http.post(`${this.API}Briefing/UploadArquivo`, formData)
  }

  public deleteArquivoBriefing(body){
    return this.http.post(`${this.API}Briefing/DeleteArquivo`, body)
  }

}
