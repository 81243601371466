import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

export interface ProdutoFocus {
  Id: number,
  ListaServicoProdutoFocus: ServicoProduto[],
  Produto: string
}

export interface ServicoProduto {
  DataInicio: string,
  Descricao: string,
  Filial: string,
  Id: number,
  IdProdutoFocus: number
}

@Injectable({
  providedIn: 'root'
})
export class FechamentoService {

  public API = environment.API

  constructor(private http: HttpClient) {

    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  public get(id) {
    return this.http.get<any>(`${this.API}FechamentoNegocio/${id}`).toPromise()
  }

  public post(corpo) {
    return this.http.post<any>(`${this.API}FechamentoNegocio`, corpo).toPromise()
  }

  public delete(id) {
    return this.http.delete<any>(`${this.API}FechamentoNegocio/${id}`).toPromise()
  }

  public getTabelaFechamento() {
    return this.http.get(`${this.API}TabelaPesquisa/VWFechamentoNegocio`)
  }

  public fazPesquisaFechamento(campo, component, filtro) {

    const httpParams = new HttpParams({

      fromObject: {
        tabela: component,
        coluna: filtro,
        valor: campo,
      }
    });

    return this.http.get(`${this.API}TabelaPesquisa/GetPesquisa`, { params: httpParams })
  }

  public getProdutos(){
    return this.http.get(`${this.API}ProdutoFocus`)
  }

  public getServicoProduto(id){
    return this.http.get(`${this.API}/ProdutoFocus/GetServicoProduto/${id}`)
  }

}
