import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ngx-pagina-pergunta',
  templateUrl: './pagina-pergunta.component.html',
  styleUrls: ['./pagina-pergunta.component.scss']
})
export class PaginaPerguntaComponent implements OnInit {

  public mensagem: string;

  constructor( private dialogRef: MatDialogRef<PaginaPerguntaComponent>, @Inject(MAT_DIALOG_DATA) data ) {

    this.mensagem = data;

   }

  ngOnInit(): void {


  }


  public Resposta(resposta: boolean) {
    this.dialogRef.close(resposta)
  }

}
