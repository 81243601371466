import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormularioPesquisaService {

  private API = environment.API
  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  get(id) {
    return this.http.get(`${this.API}Modelo/${id}`)
  }

  post(form) {
    return this.http.post(`${this.API}Modelo`, form)
  }

  delete(id) {
    return this.http.delete(`${this.API}Modelo/${id}`)
  }

  getPesquisa(id){
    return this.http.get(`${this.API}Pesquisa/${id}`)
  }

  postPesquisa(corpo) {
    return this.http.post(`${this.API}Pesquisa`, corpo)
  }

  deletePesquisa(id){
    return this.http.delete(`${this.API}Pesquisa/${id}`)
  }

  public getTabelaModelo() {
    return this.http.get(`${this.API}TabelaPesquisa/Modelo`);
  }

  public getTabelaPesquisa() {
    return this.http.get(`${this.API}TabelaPesquisa/VWPesquisa`)
  }

  fazPesquisaFormularioPesquisa(filtro, componente, campo) {
    const httpParams = new HttpParams({
      fromObject: {
        tabela: componente,
        coluna: campo,
        valor: filtro
      }
    })

    return this.http.get<any>(`${this.API}TabelaPesquisa/GetPesquisa`, {params: httpParams})
  }

  fazPesquisaModelo(filtro, componente, campo) {

    const httpParams = new HttpParams({
      fromObject: {
        tabela: componente,
        coluna: campo,
        valor: filtro
      }
    })

    return this.http.get<any>(`${this.API}TabelaPesquisa/GetPesquisa`, { params: httpParams })
  }
}
