import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { LoginService } from '../../services/login.service';
import { NbMenuItem } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class PagesResolve implements Resolve<any>{

  constructor(private loginService: LoginService) { }

  async resolve() {

    let menu: NbMenuItem[] =
    [
      {
        title: 'Principal',
        icon: 'home-outline',
        
        link: '/pages/principal',
        home: true,
      },
      {
        title: 'OPÇÕES',
        group: true,
      },
      {
        title: 'Cadastro',
        icon: 'edit-2-outline',
      },
      {
        title: 'Operações',
        icon: 'grid-outline',
      },

      {
        title: 'Relatórios',
        icon: 'pie-chart-outline',
      },

      {
        title: 'Segurança',
        icon: 'lock-outline',
      },
    ];

    await this.loginService.pegarDadosCompletos()

    await this.loginService.getPermissoesUsuario()
      .then(response => {

        let filhosCad = []
        let filhosRel = []
        let filhosOp = []
        let filhosSeg = []

        // ADICIONARÁ AO MÓDULO O SEU RESPECTIVO COMPONENTE

        response.forEach(linha => {

          // BLOCO DE CÓDIGO PARA RECEBER NÚMERO DO MÓDULO E DO COMPONENTE A PARTIR DO CDFUNCAO
          let modulo = parseInt(linha.cdFuncao)
          let componente = modulo % 100
          modulo = modulo/1000
          modulo = Math.trunc(modulo)

          // OBJETO DO ITEM QUE SERÁ ADICIONADO NO MÓDULO
          let item = {
            link: "",
            title: "",
            Permissao: ""
          }

          // COLOCARÁ NO MODULO SEU RESPECTIVO FILHO DE ACORDO COM O COMPONENTE
          switch (modulo){
            case 1:
                    if (componente == 1){
                      item.link = '/pages/cadastros/cadastro-cliente'
                    }

                    if (componente == 2){
                      item.link = '/pages/cadastros/atendimento'
                    }

                    if (componente == 3){
                      item.link = '/pages/cadastros/tipo-servico'
                    }

                    if (componente == 4){
                      item.link = '/pages/cadastros/unidade'
                    }

                    if (componente == 5){
                      item.link = '/pages/cadastros/equipe'
                    }

                     if (componente == 6){
                       item.link = '/pages/cadastros/cliente-prospect'
                     }

                    if (componente == 7) {
                      item.link = '/pages/cadastros/cadastro-feriado'
                     }

                    if (componente == 8) {
                      item.link = '/pages/cadastros/cadastro-contato-empresa'
                    }

                    if (componente == 9) {
                      item.link = "/pages/cadastros/ata-reuniao"
                    }

                    if (componente == 11) {
                      item.link = "/pages/cadastros/cadastro-tipo-visita"
                    }

                    if (componente == 12){
                      item.link = "/pages/cadastros/empresas-colaborador"
                    }

                    if (componente == 13){
                      item.link = '/pages/cadastros/cadastro-documentos'
                    }

                    if(componente == 14){
                      item.link = '/pages/cadastros/empresa-equipes'
                    }

                    if(componente == 15){
                      item.link = '/pages/cadastros/registro-visita-comercial'
                    }
                    if(componente == 16){
                      item.link = '/pages/cadastros/cadastro-pesquisa'
                    }

                    item.title = linha.dsFuncao,
                    item.Permissao =  linha.Permissao

                    filhosCad.push(item)

                    menu[2]['children'] = filhosCad

              break;
            case 2:
                   if (componente == 1) {
                     item.link = '/pages/operacoes/consultasolicitacoes'
                    }
                   if (componente == 2) {
                     item.link = '/pages/operacoes/controle-prospect'
                   }
                   if (componente == 3){
                     item.link = '/pages/operacoes/agenda'
                   }
                   if (componente == 4) {
                    item.link = "/pages/operacoes/briefing"
                  }
                  if (componente == 5) {
                    item.link = "/pages/operacoes/fechamento-negocio"
                  }
                  if (componente == 6) {
                    item.link = '/pages/operacoes/consulta-prazos'
                  }
                  if (componente == 7){
                    item.link = '/pages/operacoes/trocar-cliente'
                  }
                  if(componente == 8){
                    item.link = '/pages/operacoes/formulario-pesquisa'
                  }

                    item.title = linha.dsFuncao,
                    item.Permissao =  linha.Permissao

                    filhosOp.push(item)

                    menu[3]['children'] = filhosOp

                    break;
            case 3:
                    if (componente == 1){
                      item.link = '/pages/relatorios/indicadores'
                    }

                    if (componente == 2){
                      item.link = '/pages/relatorios/relatorios-agenda'
                    }
                    if (componente == 3){
                      item.link = '/pages/relatorios/relatorios-solicitacao'
                    }
                    if (componente == 4){
                      item.link = '/pages/relatorios/relatorios-prospect'
                    }
                    if (componente == 5){
                      item.link = '/pages/relatorios/relatorios-ata-reuniao'
                    }
                    if (componente == 6){
                      item.link = '/pages/relatorios/relatorios-briefing'
                    }
                    if (componente == 7){
                      item.link = '/pages/relatorios/relatorios-pessoa-fisica'
                    }
                    if (componente == 8){
                      item.link = '/pages/relatorios/relatorios-pessoa-juridica'
                    }
                    if (componente == 9){
                      item.link = '/pages/relatorios/listagem-ata-reuniao'
                    }
                    if (componente == 10){
                      item.link = '/pages/relatorios/listagem-briefing-comercial'
                    }
                    if (componente == 11){
                      item.link = '/pages/relatorios/historico-anotacao'
                    }
                    if(componente == 12){
                      item.link = '/pages/relatorios/briefing-financeiro'
                    }

                    item.title = linha.dsFuncao,
                    item.Permissao = linha.Permissao

                    filhosRel.push(item)
                    menu[4]['children'] = filhosRel
                    break;
            case 4:
                    if (componente == 1){
                      item.link = '/pages/seguranca/perfilacesso'
                    }

                    if (componente == 2){
                      item.link = '/pages/seguranca/usuario'
                    }

                    if (componente == 3){
                      item.link = '/pages/seguranca/parametros'
                    }

                    item.title = linha.dsFuncao,
                    item.Permissao =  linha.Permissao

                    filhosSeg.push(item)

                    menu[5]['children'] = filhosSeg

                    break;

          }

        })

        // BLOCO DE CÓDIGO PARA ELIMINAR DO MENU OS MÓDULOS QUE NÃO POSSUEM FILHOS
        let valor = 2
        if (filhosCad.length == 0){
          menu.splice(valor, 1)
        } else {
          valor++
        }
        if (filhosOp.length == 0){
          menu.splice(valor, 1)
        } else {
          valor++
        }
        if (filhosRel.length == 0){
          menu.splice(valor, 1)
        } else {
          valor++
        }
        if (filhosSeg.length == 0){
          menu.splice(valor, 1)
        } else {
          valor++
        }


      })

      this.loginService.setPermissaoComponente(menu)

    return menu

  }

}

