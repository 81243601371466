import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AtendimentoService {

  API = environment.API

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  get(id){
    return this.http.get(`${this.API}Atendimento/${id}`).toPromise()
  }


  post(atendimento){
    return this.http.post<any>(`${this.API}Atendimento`, atendimento).toPromise()
  }

  delete(id){
    return this.http.delete(`${this.API}Atendimento/${id}`).toPromise()
  }

  getTabelaAtendimento(){
    return this.http.get<any>(`${this.API}TabelaPesquisa/VWAtendimento_TabPesquisa`)
  }


  fazPesquisaAtendimento(filtro, componente, campo){

    componente = "VWAtendimento_TabPesquisa"
   
    const httpParams = new HttpParams({
      fromObject: {
        tabela: componente,
        coluna: campo,
        valor: filtro
      }
    })

    return this.http.get<any>(`${this.API}TabelaPesquisa/GetPesquisa`, {params: httpParams})
  }

  postSolicitacao(solicitacao){
    return this.http.post<any>(`${this.API}Atendimento/GravaSolicitacao`,solicitacao)
  }



}
