import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { Empresa } from '../app/pages/cadastros/empresas-colaborador/empresas-colaborador.component';
import { CnpjPipe } from '../pipes/cnpj.pipe';
import { FormValidations } from './form-validations';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  buscaCNPJ(cnpj: any, response: any) {
    throw new Error('Method not implemented.');
  }

  private API = environment.API

  public enderecoPreencher = new Subject<any>()

  public formularioEndereco = new Subject<any>()
  public formularioPessoaFisica = new Subject<any>()
  public formularioContato = new Subject<any>()

  public listaContatos = new Subject<any>()
  public listaEmpresas = new Subject<any>()
  public listaArquivos = []

  public listaCNPJ = []

  constructor(private http: HttpClient,
    public formValidations: FormValidations) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  enviaFormularioEndereco(formulario){
    this.formularioEndereco.next(formulario)
  }

  enviaFormularioPessoaFisica(formulario){
    this.formularioPessoaFisica.next(formulario)
  }

  enviaFormularioContato(formulario){
    this.formularioContato.next(formulario)
  }

  enviaListaContatos(listaContato){
    this.listaContatos.next(listaContato)
  }

  enviaListaEmpresas(listaEmpresa){
    this.listaEmpresas.next(listaEmpresa)
  }

 enviarEnderecoPreencher(formulario){
    this.enderecoPreencher.next(formulario)
  }

  buscarEndereco(cep): Promise<any>{

    return this.http.get(`https://viacep.com.br/ws/${cep}/json`).toPromise()
  }

  getTokenFromSerpro(): Promise<any>{
    return this.http.get(`${this.API}IntegracaoSERPRO/GetToken`).toPromise();
  }

  buscarCNPJ(CNPJ, token?): Promise<any>{

    if(token){
      localStorage.setItem('TokenSerpro', token.access_token);
    }


    const tokenSerpro = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('TokenSerpro')}`,
      // 'x-cpf-usuario': '08656245775'
    })

    // headers.set('x-cpf-usuario', '15222768740')
    // headers.set('Authorization', 'Bearer 06aef429-a981-3ec5-a1f8-71d38d86481e')


    return this.http.get(`https://gateway.apiserpro.serpro.gov.br/consulta-cnpj-df/v2/basica/${FormValidations.retirarFormatacao(CNPJ)}`, {headers: tokenSerpro}).toPromise();
  }

  buscarRegiao(ibge): Promise<any>{
    return this.http.get(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${ibge}`).toPromise()
  }

  buscarMunicipio(ibge): Promise<any> {
    return this.http.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ibge}/municipios`).toPromise()
  }

  getCliente(id) : Promise<any>{
    return this.http.get(`${this.API}Cliente/${id}`).toPromise()
  }

  setClientePJ(cadastroPJ) : Promise<any>{
    return this.http.post(`${this.API}Cliente/clientepjuridica`, cadastroPJ, {observe: 'response'}).toPromise()
  }

  setClientePF(cadastroPF) : Promise<any>{
    return this.http.post(`${this.API}Cliente/clientepfisica`, cadastroPF, {observe: 'response'}).toPromise()
  }

  deleteCliente(id){
    return this.http.delete(`${this.API}Cliente/${id}`).toPromise()
  }

  getTabelaCliente(){
    return this.http.get<any>(`${this.API}TabelaPesquisa/Cliente`)
  }

  fazPesquisaCliente(filtro, componente, campo, condicao?){

    let httpParams

    if(condicao){
      httpParams = new HttpParams({
        fromObject: {
          tabela: componente,
          coluna: campo,
          valor: filtro,
          condicaoPadrao: condicao
        }
      })
    } else {
      httpParams = new HttpParams({
        fromObject: {
          tabela: componente,
          coluna: campo,
          valor: filtro
        }
      })
    }

    return this.http.get<any>(`${this.API}TabelaPesquisa/GetPesquisa`, {params: httpParams})

  }

  getFiliais(){
    return this.http.get<any[]>(`${this.API}ConsultaSolicitacao/GetFilial`).toPromise()
  }

  mudaListaCNPJ(cnpjAntigo, cnpjNovo){
    if(cnpjAntigo != cnpjNovo){

      for (let i = 0; i < this.listaCNPJ.length; i++){
        if(this.listaCNPJ[i] == cnpjAntigo){
          this.listaCNPJ[i] = cnpjNovo
          break;
        }
      }

    }
  }

  excluiItemListaCNPJ(cnpj){

    for (let i = 0; i < this.listaCNPJ.length; i++){
      if(this.listaCNPJ[i] == cnpj){
        this.listaCNPJ.splice(i, 1)
      }
    }

  }

  getUltimoClienteCadastradoUsuario(){
    return this.http.get<number>(`${this.API}Cliente/GetUltimoClienteCadastrado/`)
  }

  getTodosClientes(){
    return this.http.get(`${this.API}/Cliente`)
  }

public getEmpresas(){
    return this.http.get<Empresa>(`${this.API}Cliente/GetEmpresas`)
  }

  public trocarEmpresaCliente(form){
    return this.http.post(`${this.API}Cliente/AlteraEmpresaCliente`, form)
  }

}
