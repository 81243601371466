import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AtendimentoSolicitacaoService {

  API = environment.API
  static ListaDepartamentosParticipante;

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

    getDepartamentos(){
      return this.http.get<any[]>(`${this.API}Departamento`).toPromise()
    }

    getTipoServico(id){
      return this.http.get<any[]>(`${this.API}TipoServico/${id}`).toPromise()
    }

    devolverRelator(obj){

      return this.http.post<any>(`${this.API}ConsultaSolicitacao/retornarelator`, obj).toPromise()
    }

    getPrazoExterno(idCliente, idTipoServico, DataCadastro){

      const httpParams = new HttpParams({
        fromObject: {
          idCliente: idCliente,
          idTipoServico: idTipoServico,
          DataCadastro: DataCadastro
        }
      })

      return this.http.get(`${this.API}Atendimento/GetPrazoExterno`, {params: httpParams}).toPromise()
    }

    gravaAPI(obj){
      return this.http.post(`${this.API}ConsultaSolicitacao/gravasolicitacao`, obj).toPromise()
    }

    getFilial(idDepartamento, idEmpresa){
      return this.http.get(`${this.API}Atendimento/GetFilial?idDepartamento=${idDepartamento}&idEmpresaCliente=${idEmpresa}`)
    }

}
