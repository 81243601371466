<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<!DOCTYPE html>
<html>

<head>
    <title>Login Page</title>
    <!--Made with love by Mutiullah Samim -->

    <!--Bootsrap 4 CDN-->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
        integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">

    <!--Fontawesome CDN-->
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css"
        integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">


</head>

<body>

    <div class="container">
        <div class="imgConteiner">
            <img src="../../../assets/imagesLogin/LOGO FOCUS 2023 PRETO (1).png">
        </div>
        <div>

            <div class="card">
                <div class="card-body">
                    <p class="login-title">Bem vindo ao CRM!</p>
                    <form [formGroup]="formulario">
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-user corIconeLetra"></i></span>
                            </div>
                            <input formControlName="Login" type="text" class="form-control" placeholder="usuário">

                        </div>
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-key corIconeLetra"></i></span>
                            </div>
                            <input formControlName="Senha" type="password" class="form-control" placeholder="senha">
                        </div>

                        <div *ngIf="aparecerSelecaoAmbiente" class="input-group form-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                        height="16" fill="currentColor" class="bi bi-gear-fill corIconeLetra"
                                        viewBox="0 0 16 16">
                                        <path
                                            d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                                    </svg></span>
                            </div>
                            <select class="form-control" formControlName='Ambiente' (change)="mudarAmbiente($event)">
                                <option *ngFor="let select of selecaoAmbiente" [value]="select">
                                    {{select}}</option>
                            </select>
                        </div>
                        <div class="manterConect remember">
                            <input formControlName="ManterConectado" type="checkbox">
                            <p>Manter-me conectado</p>
                        </div>

                        <div style="display: flex !important; justify-content: space-between;">

                            <div class="mt-2">

                            </div>

                            <div>
                                <button [disabled]="formulario.status == 'INVALID' ? true : false"
                                    [ngClass]="formulario.status == 'INVALID' ? 'btn float-right btn_invalido' : 'btn float-right btn_valido' "
                                    (click)="fazerLogin()" type="submit">

                                    <div *ngIf="!aparecerSpinner; else spinner">
                                        Login
                                    </div>

                                    <ng-template #spinner>
                                        <mat-spinner disabled [diameter]="24" style="margin-left: 23px"
                                            class="mat-spinner-color"></mat-spinner>
                                    </ng-template>

                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <mat-toolbar class="footer" style="z-index: 1;">
        <span class="version"><strong >Versão 5.202408</strong><strong>CRM Focus | Cuidando dos clientes, conectando pessoas e processos. Gerando experiências</strong></span>
    </mat-toolbar>
</body>

</html>