
<div class="pyro">
  <div class="before"></div>
  <div class="after"></div>
</div>

<div id="containerimg">
  <button mat-icon-button (click)="fecharDialogMatch()" style="outline-width: 0; z-index: 1;">
    <mat-icon class="corIconeLetra" >close</mat-icon>
  </button>
  
  <div class="centerCliente">{{ Notificacao.Descricao }}</div>
  <img class="imgTamanho" style="border-radius: 25px;" src='../../../../assets/images/deu match.gif' height="405" width="750">
 </div>


