import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeriadoService {

  API = environment.API

constructor(private http:HttpClient) {
  environment.emitAPI.subscribe(api => {
    this.API = api
  })
}

get(id){
  return this.http.get(`${this.API}Feriado/${id}`)
}
getTabelaFeriado(){
  return this.http.get(`${this.API}TabelaPesquisa/VWFeriado/`)
}
post(feriado) {
  return this.http.post<any>(`${this.API}Feriado`, feriado)
}
delete(id){
  return this.http.delete(`${this.API}Feriado/${id}`)
}
// FUNCAO PARA REALIZAR PESQUISA
public fazPesquisaFeriado(campo, componente, filtro) {
  const httpParams = new HttpParams({
    fromObject: {
      tabela: componente,
      coluna: filtro,
      valor: campo
    }
  })
  return this.http.get(`${this.API}TabelaPesquisa/GetPesquisa`, {params: httpParams})
}

}
