import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilaAtendimentoComponent } from './fila-atendimento/fila-atendimento.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { NbChatModule, NbInputModule, NbThemeModule, NbWindowModule } from '@nebular/theme';
import { ChatComponent } from './chat/chat.component';
import { TrocaFilaChatComponent } from './chat/troca-fila-chat/troca-fila-chat.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChatMessageExtendsComponent } from './chat/chat-message-extends/chat-message-extends.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ChatFormExtendsComponent } from './chat/chat-form-extends/chat-form-extends.component';
import { HistoricoConversaComponent } from './historico-conversa/historico-conversa.component';


const components = [
  FilaAtendimentoComponent,
  ChatComponent,
  TrocaFilaChatComponent,
  ChatMessageExtendsComponent,
  ChatFormExtendsComponent,
  HistoricoConversaComponent
]

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatSelectModule,
    MatTooltipModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    NbWindowModule.forRoot(),
    NbChatModule.forRoot(),
    MatDividerModule,
    MatAutocompleteModule,
    MatTooltipModule,
    NbInputModule
  ],
  exports: [...components],
  declarations: [...components,
  ],
  providers: [DatePipe]
})
export class AtendimentoWhatsappModule { }
