import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContatoEmpresaService {

  API = environment.API

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }


  getTabelaContato() {
    return this.http.get(`${this.API}TabelaPesquisa/`)
  }


  // FUNCAO PARA ENVIAR CONTATOS PARA O BANCO
  public post(Empresa) {
    return this.http.post<any>(`${this.API}ContatoCliente/`, Empresa)
  }

  //FUNCAO PARA RETORNAR TABELA DE CONTATOS DA EMPRESA

  get(id) {
    return this.http.get(`${this.API}ContatoCliente/${id}`)
  }


  // FUNCAO PARA REALIZAR PESQUISA 

  public fazPesquisaContato(campo, componente, filtro) {

    const httpParams = new HttpParams({
      fromObject: {
        tabela: componente,
        coluna: filtro,
        valor: campo
      }
    })
    return this.http.get(`${this.API}/TabelaPesquisa/GetPesquisa`, { params: httpParams })
  }

  //GUARDA OS CONTATOS NO SQL E NO MONGO
  public gravarContato(body) {
    return this.http.post(`${this.API}ContatoCliente/GravarContato`, body)
  }

  //Pega todos os contatos cadastrados no cliente e nas empresas do cliente.
  public getContatoChat(idCliente): Observable<any> {
    return this.http.get(`${this.API}ContatoCliente/GetContatoEmpresas/${idCliente}`)
  }
}
