import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificacaoService } from '../../../../services/notificacao.service';

@Component({
  selector: 'ngx-notificacao-match',
  templateUrl: './notificacao-match.component.html',
  styleUrls: ['./notificacao-match.component.scss']
})


export class NotificacaoMatchComponent implements OnInit {

  public nomeClienteNotificacao: String
  public Notificacao
  public statusResponse


  constructor(public dialog: MatDialogRef<NotificacaoMatchComponent>,
    private notificacaoService: NotificacaoService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.Notificacao = data;

  }
  ngOnInit() {
   this.marcarLido()
  }



  public marcarLido() {

    this.Notificacao.Lido = 1
    this.notificacaoService.post([this.Notificacao]).then(
      response => {
        if(response[0].Codigo == "CRM00001"){
        this.statusResponse = true
        }
        else{
          this.statusResponse = false
        }
      }
    )
    .catch(erro => console.log(erro))
  }

  fecharDialogMatch() {
    this.dialog.close(this.statusResponse)

  }

}
