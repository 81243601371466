import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EquipeService {

  API = environment.API

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  public fazPesquisaEquipe(filtro, componente, campo,) {
    const HTTP_PARAMS = new HttpParams({
      fromObject: {
        tabela: componente,
        coluna: campo,
        valor: filtro
      }
    })
    return this.http.get<any[]>(`${this.API}TabelaPesquisa/GetPesquisa`, { params: HTTP_PARAMS} );
  }

  public getTabelaEquipe() {
    return this.http.get(`${this.API}TabelaPesquisa/VWEquipe`);
  }

  public ColetarEquipeIndividual(id: number): Promise<any> {
    return this.http.get(`${this.API}Equipe/${id}`).toPromise()
  }

  public NovaEquipe(dados: object): Promise<any> {
    return this.http.post(`${this.API}Equipe`, dados).toPromise()
  }

  public DeletarEquipeIndividual(id: number): Promise<any> {
    return this.http.delete(`${this.API}Equipe/${id}`).toPromise()
  }

  public getColaboradores(id?): Promise<any> {

    if(id){
      return this.http.get(`${this.API}ConsultaSolicitacao/GetEquipe/${id}`).toPromise();
    } else {
      return this.http.get(`${this.API}ConsultaSolicitacao/GetEquipe/0`).toPromise();
    }
  }

  public getColaboradoresEquipeIdDepartamento(id){
    return this.http.get<any[]>(`${this.API}Equipe/RetornaColaboradoresEquipeIdDepartamento/${id}`)
  }

  public getColaboradoresEquipeNomeDepartamento(departamento){
    return this.http.get<any[]>(`${this.API}Equipe/RetornaColaboradoresEquipeNomeDepartamento/${departamento}`)
  }

  public getEquipesNegócios(){
    return this.http.get<any[]>(`${this.API}Equipe/GetEquipeNegocio`)
  }

  public getAll(){
    return this.http.get<any[]>(`${this.API}Equipe`)
  }

  public getEquipeFila(){
    return this.http.get<any[]>(`${this.API}Equipe/GetFilaChat`)
  }

  public getEquipeChat(){
    return this.http.get<any[]>(`${this.API}Equipe/GetEquipeChat`)
  }
}
