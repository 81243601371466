import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'


import { FuncaoSistema } from '../models/funcaoSistema';
import { AsyncSubject, Observable, Subject, ReplaySubject } from 'rxjs';
import { LoginService } from './login.service';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PerfilAcessoService {

  private API = environment.API

  private readonly endTOKEN = 'http://192.168.25.6:8086/api/account/login'

  
  subject = new AsyncSubject();

  emitirMenssagem = new EventEmitter<any>();

  observable = new Observable();

  constructor(private http: HttpClient,
              private loginService: LoginService) {
                environment.emitAPI.subscribe(api => {
                  this.API = api
                })
              }

  // MÉTODO PARA ADQUIRIR UM PERFIL COM UM TOKEN
  getToken(){
    return this.http.post<any>(`${this.API}account/login`, this.loginService.getUsuario())
  }

  // MÉTODO PARA TRANFORMAR O TOKEN ADQUIRIDO EM UM TOKEN VÁLIDO
  getTokenHeader(token){
    return {'Authorization': `Bearer ${token.token}`}
  }

  // É PASSADO O VALOR DO TOKEN O CABEÇALHO DO MÉTODO GET
  list(valToken){    
    const httpHeader = new HttpHeaders({
      'Authorization': `Bearer ${valToken.token}`
    })
    
    return this.http.get<FuncaoSistema[]>(`${this.API}perfilacesso`, {headers: httpHeader})
  }
  
  get(id, token){
    let acesso = this.getTokenHeader(token)
    return this.http.get<any>(`${this.API}perfilacesso/${id}`, {headers: acesso})
  }

  // CRIAR UM PERFIL DE ACESSO
  async post(perfil, token){
    let menssagem

    let acesso = this.getTokenHeader(token)


    this.subject.closed = false
    await this.http.post(`${this.API}PerfilAcesso`, perfil, {headers: acesso, observe: 'response'})
      .subscribe(      

        data => {
          menssagem = data.body
          this.emitirMenssagem.emit(menssagem)
        },        
        error => {
          menssagem = [{Codigo: error.status, Descricao: error.name, MensagemComplementar: error.message, TipoMensagem: 2}]
          this.emitirMenssagem.emit(menssagem)
        }
      )

  }

  async delete(id, token){
    let acesso = this.getTokenHeader(token)

    await this.http.delete(`${this.API}perfilacesso/${id}`, {headers: acesso, observe: 'response'})
      .subscribe(
      
      dados => {
        this.emitirMenssagem.emit(dados.body)

      },

      error => {
        this.emitirMenssagem.emit([{Codigo: error.status, Descricao: error.name, MensagemComplementar: error.message, TipoMensagem: 2}])

      }
    )
  }

  getTabelaPerfilAcesso(token){
    let acesso = this.getTokenHeader(token)
    
    return this.http.get<any>(`${this.API}TabelaPesquisa/PerfilAcesso`, {headers: acesso})
  }

  fazPesquisaPerfilAcesso(token, componente, filtro, campo){

    const httpParams = new HttpParams({
      fromObject: {
        tabela: componente,
        coluna: campo,
        valor: filtro
      }
    })

    let acesso = this.getTokenHeader(token)
    return this.http.get<any>(`${this.API}TabelaPesquisa/GetPesquisa`, {headers: acesso, params: httpParams})
  }
}
