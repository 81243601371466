import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidations {

  constructor(private http: HttpClient){}

  static cepValidator(control: FormControl){

    let cep = control.value
    let regExp = /^\d{5}-?\d{3}$/
    return regExp.test(cep) ? null : { cepValidator: true }
  }

  static cpfValidator(control: FormControl){

    let cep = control.value
    let regExp = /^\d{3}.?\d{3}.?\d{3}-?\d{2}$/
    return regExp.test(cep) ? null : { cpfValidator: true }

  }

  static cnpjValidator(control: FormControl){

    let cnpj = control.value
    let regExp = /^\d{2}.?\d{3}.?\d{3}\/?\d{4}-?\d{2}$/
    return regExp.test(cnpj) ? null : { cnpjValidator: true }

  }

  // VALIDA DATAS DD/MM
  static dataValidator(control: FormControl){

    let data = control.value
    let regExp = /^(0[0-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])$/
    return regExp.test(data) ? null : { dataValidator: true }

  }

  static minLengthArray(min: number) {
    return (c: AbstractControl): {[key: string]: any} => {
        if (c.value.length >= min)
            return null;

        return { 'minLengthArray': {valid: false }};
    }
  }
  

  static dataIgualAnterior(control: FormControl){

    let data = control.value

    var dt = new Date();
    let ano = `${dt.getFullYear().toString().padStart(4, '0')}`
    let mes = `${(dt.getMonth()+1).toString().padStart(2, '0')}`
    let dia = `${dt.getDate().toString().padStart(2, '0')}`



    if (data && data != ""){

      if (data.slice(0,4) > ano){
        return { dataIgualAnterior: true }
      } else {

        if(data.slice(5,7) > mes){

          if(ano == data.slice(0,4)){
            return { dataIgualAnterior: true }
          }

        }

        if(data.slice(8,10) > dia){
          return mes == data.slice(5,7) ? { dataIgualAnterior: true } : null
        } else {
          return null
        }
      }


    }




  }


  static dataMaiorOuIgual(control: FormControl){

    let data = control.value

    var dt = new Date();
    let ano = `${dt.getFullYear().toString().padStart(4, '0')}`
    let mes = `${(dt.getMonth()+1).toString().padStart(2, '0')}`
    let dia = `${dt.getDate().toString().padStart(2, '0')}`



    if (data && data != ""){

      if (data.slice(0,4) < ano){
        return { dataIgualAnterior: true }
      } else {

        if(data.slice(5,7) < mes){

          if(ano == data.slice(0,4)){
            return { dataIgualAnterior: true }
          }

        }

        if(data.slice(8,10) < dia){

          if(mes == data.slice(5,7)){
            return data.slice(0,4) == ano ? { dataIgualAnterior: true } : null
          }
          
        } else {
          return null
        }
      }


    }




  }


  // VALIDA DATAS DD/MM/AAAA
  static dataDMAValidator(control: FormControl){

    let data = control.value
    let regExp = /^(0[0-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(\n{4})$/
    return regExp.test(data) ? null : { dataDMAValidator: true }

  }

  static matrizNaoExistente(listaCNPJ){

    const validator = (control: FormControl) => {

      let matriz = control.value
      let achado = true

      if (matriz && matriz != "" && listaCNPJ.length > 0){
        matriz = matriz.replace(/(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{2})/, "$1$2$3$4$5")
        listaCNPJ.forEach(linha => matriz == linha ? achado = true : achado = false)
      }

      return achado ? null : { matrizNaoExistente: true }
    }
    return validator

  }

  static cnpjExistente(listaCNPJ){

    const validator = (control: FormControl) => {

      let cnpj = control.value

      if (cnpj && cnpj != "" && listaCNPJ.length > 0){
        cnpj = cnpj.replace(/(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{2})/, "$1$2$3$4$5")
        return listaCNPJ.includes(cnpj) ? { cnpjExistente: true } : null
      }

    }
    return validator

  }

  static somenteLetra(control: FormControl){

    let valor = control.value
    let regExp = /^\d*$/
    return regExp.test(valor) ? { naoPermitirEspeciais: true } : null

  }

  static somenteNumero(control: FormControl){

    let valor = control.value

    if(valor && valor != ""){
      let regExp = /^[A-Za-zzáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]*$/
      return regExp.test(valor) ? { somenteNumero: true } : null
    }

    return null

  }

  static telefoneValidator(control: FormControl){

    let telefone = control.value

    if(telefone && telefone != ""){
      let regExp = /^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/
      return regExp.test(telefone) ? null : { telefoneValidator: true }
    }

    return null


  }

  static validaCPFCNPJ(control: FormControl){

    let valor = control.value
    valor = this.retirarFormatacao(valor)

  }

  static formatarCampo(valor, formatacao){

    if(valor && valor != ""){
      switch (formatacao){
        case "cep":
          return valor.replace(/(\d{5})-?(\d{3})/, "$1-$2")
        case "cpf":
          return valor.replace(/(\d{3}).?(\d{3}).?(\d{3})-?(\d{2})/, "$1.$2.$3-$4")
        case "cnpj":
          return valor.replace(/(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{2})/, "$1.$2.$3/$4-$5")
        case "data":
          return valor.replace(/(\d{2})\/?(\d{2})/, "$1/$2")
        case "dataDMA":
          return valor.replace(/(\d{2})\/?(\d{2})\/?(\d{4})/, "$1/$2/$3")
        case "dataAMDtoDMA":
          return valor.replace(/(\d{4})-(\d{2})-(\d{2})/, "$3/$2/$1")
        case "dataDMAtoAMD":
          return valor.replace(/(\d{2})-?\/?(\d{2})-?\/?(\d{4})/, "$3-$2-$1")
        case "telefone":
          valor = valor.replace(/\D/g, "");
          valor = valor.replace(/^(\d\d)(\d)/g, "($1)$2");

          if (valor.length < 13) {
            valor = valor.replace(/(\d{4})(\d)/, "$1-$2");
          } else {
            valor = valor.replace(/(\d{5})(\d)/, "$1-$2");
          }

          return valor;
      }
    }

  }

  // PASSA UMA DATA DO FORMATO YYYY-MM-DDTHH:MM:SS PARA YYYY-MM-DD
  static retirarFormatacaoDataHora(valor){
    if (valor && valor != "")
    return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})-?(\d{2})?:?(\d{2})?$/, "$1-$2-$3")
  }

  static retiraMilesimos(valor){
    if (valor && valor != ""){
      return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{2,3})$/, "$1-$2-$3T$4:$5:$6")
    }
  }

  static formatarDataHoraAPI(valor){

    if(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(valor)){
      return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/, "$3/$2/$1 $4:$5:$6")
    }

    if(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{1,3}$/.test(valor)){
      return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{1,3})$/, "$3/$2/$1 $4:$5:$6")
    }

    if(/^\d{2}\/\d{2}\/\d{4}\s\d{2}:\d{2}:\d{2}$/.test(valor)){
      return valor.replace(/^(\d{2})\/(\d{2})\/(\d{4})\s(\d{2}):(\d{2}):(\d{2})/, "$3-$2-$1T$4:$5:$6")
    }


  }

  static retornaMesAno(valor){
    if(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(valor)){
      return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/, "$2/$1")
    }
  }

  public static retirarFormatacao(valor){

    if(/^\d{5}-?\d{3}$/.test(valor)){
      return valor.replace(/(\d{5})-?(\d{3})/, "$1$2")
    }

    if(/^\d{3}.?\d{3}.?\d{3}-?\d{2}$/.test(valor)){
      return valor.replace(/(\d{3}).?(\d{3}).?(\d{3})-?(\d{2})/, "$1$2$3$4")
    }

    if(/^\d{2}.?\d{3}.?\d{3}\/?\d{4}-?\d{2}$/.test(valor)){
      return valor.replace(/(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{2})/, "$1$2$3$4$5")
    }

    if(/^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/.test(valor)){
      return valor.replace(/\(?(\d{2})\)?\s?(\d{4,5})-?(\d{4})/, "$1$2$3")
    }

  }

  // CRIA OU ALTERA UMA DATA PARA O FORMATO ACEITO NA API
  static criarDataAPI(data?){
    var dt = new Date();

    if(data){
      return `${data}T00:00:00`
    }

    return `${dt.getFullYear().toString().padStart(4, '0')}-${(dt.getMonth()+1).toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}T${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}:${dt.getSeconds().toString().padStart(2, '0')}`
  }

  // CRIA DATA NO FORMATO DD/MM/YYYY HH:MM:SS
  static criarData(){
    var dt = new Date();

    return `${dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth()+1).toString().padStart(2, '0')}/${dt.getFullYear().toString().padStart(4, '0')} ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}:${dt.getSeconds().toString().padStart(2, '0')}`
  }

  static criarDataAMD(){
    var dt = new Date();

    return `${dt.getFullYear().toString().padStart(4, '0')}-${(dt.getMonth()+1).toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}T${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}:${dt.getSeconds().toString().padStart(2, '0')}`
  }

  static criarDataHora(){
    var dt = new Date();

    return `${dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth()+1).toString().padStart(2, '0')}/${dt.getFullYear().toString().padStart(4, '0')} ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`
  }

}
