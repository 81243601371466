import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnidadeService {

  API = environment.API;

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  public getTabelaUnidade() {
    return this.http.get<any[]>(`${this.API}TabelaPesquisa/Filial`);
  }

  public fazPesquisaUnidade(filtro, componente, campo) {
    const HTTP_PARAMS = new HttpParams({
      fromObject: {
        tabela: componente, 
        coluna: campo,
        valor: filtro
      }
    });
    return this.http.get(`${this.API}TabelaPesquisa/GetPesquisa`, { params: HTTP_PARAMS});
  }

  public UnidadeIndividual(id: number): Promise<any> {
    return this.http.get(`${this.API}Unidade/${id}`).toPromise()
  }

  public NovaUnidade(dados: object): Promise<any> {
    return this.http.post(`${this.API}Unidade`, dados).toPromise()
  }

  public deletarTipoServicoIndividual(id: number): Promise<any> {
    return this.http.delete(`${this.API}Unidade/${id}`).toPromise()
  }



}
