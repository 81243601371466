<!-- <div> -->
<div class="backgroundwpp">
  <div class="row d-flex justify-content-around topBarChat" *ngIf="connectionChat.state == 'Connected'">
    <button class="button-font" color="blue" mat-raised-button (click)="AbrirPaginaPergunta()">Encerrar atendimento</button>
    <button class="button-font" color="grey" mat-raised-button (click)="trocarFila()">Transferir atendimento</button>
  </div>
  <div style="width:100%; background: rgba(1,1,1,0);">
    <app-chat-extends size="small" *ngIf="connectionChat.state == 'Connected'">
      <div class="scrollable" #scrollable [scrollTop]="scrollable.scrollHeight">
        <app-chat-message-extends *ngFor="let msg of chat.messages" class="message" [type]="msg.type"
          [message]="msg.text" [reply]="msg.reply" [sender]="msg.user.name" [date]="msg.date" [files]="msg.files"
          [quote]="msg.quote" [customMessage]="msg.customMessage" [avatar]="avatar">
        </app-chat-message-extends>
      </div>
      <app-chat-form-extends messagePlaceholder="Digite uma mensagem..." (enviarAnexo)="sendMessage($event)" (send)="sendMessage($event)" [dropFiles]="true" [Conexao]="contato">
      </app-chat-form-extends>
    </app-chat-extends>
    <div *ngIf="connectionChat.state == 'Reconnecting'" class=" d-flex justify-content-center">
      <h6>Conexão instável... Reconectando...</h6>
      <mat-spinner disabled [diameter]="50" style="margin-left: 3px" class="mat-spinner-color"></mat-spinner>
    </div>
    <div *ngIf="connectionChat.state == 'Disconnected'" class="d-flex justify-content-center">
      <h6>Não foi possível reestabelecer a conexão, verifique a rede.</h6>
    </div>
  </div>
</div>
<!-- </div> -->