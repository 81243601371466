import { T } from '@angular/cdk/keycodes';
import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EventEmitter } from 'events';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

import { LoginService } from '../../../services/login.service';
import { Ambiente } from '../../ambiente/ambiente';


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterContentInit {

  formulario: FormGroup;

  public $incricaoLoginEfetuado = this.loginService.emiteMenssagem
  public aparecerSpinner = false;
  static ValorAmbiente = new BehaviorSubject<any>(Ambiente.configAPI.link)

  public selecaoAmbiente = ['Produção', 'Homologação']
  public aparecerSelecaoAmbiente = environment.production

  public ambienteAtual = Ambiente.configAPI.Ambiente

  constructor(private formBuilder: FormBuilder,
    private loginService: LoginService,
    private cookie: CookieService) { }

  ngAfterContentInit(): void {

    if(!this.cookie.get('Login')){
      this.formulario.patchValue({
        Ambiente: "Produção"
      })
    }
    
  }

  ngOnInit(): void {

    this.$incricaoLoginEfetuado.subscribe(response => {
      if (response) {
        this.aparecerSpinner = false;
        alert("Usuário ou senha está incorreto")
      } else {
        this.$incricaoLoginEfetuado.unsubscribe();
      }
    })

    // CRIAÇÃO FORMULÁRIO
    this.formulario = this.formBuilder.group({
      Login: [null, Validators.required],
      Senha: [null, Validators.required],
      ManterConectado: [false],
      Ambiente: Ambiente.configAPI.Ambiente,
    })


    this.formulario.controls.ManterConectado.valueChanges.subscribe(
      valor => {
        if (valor == false) {
          this.cookie.deleteAll()
        }
      }
    )


    this.formulario.controls.Ambiente.valueChanges.subscribe(
      valor => {

        if (valor == 'Produção') {

          //LoginComponent.ValorAmbiente.next('https://backendcrm.sistemasfocuscontabil.com/api/')
          environment.emitir('CRMFocus')

          // Ambiente.configAPI.nomeBanco = 'CRMFocus'
          // Ambiente.configAPI.link = 'https://backendcrm.sistemasfocuscontabil.com/api/'
          // Ambiente.configAPI.flagHomologacao = false
          // Ambiente.configAPI.Ambiente = 'Produção'
        }

        if (valor == 'Homologação') {

          environment.emitir('CRMFocus_HOM')
          //LoginComponent.ValorAmbiente.next('https://backendcrm.sistemasfocuscontabil.com/homologacao/api/')

          // Ambiente.configAPI.nomeBanco = 'CRMFocus_HOM'
          // Ambiente.configAPI.link = 'https://backendcrm.sistemasfocuscontabil.com/homologacao/api/'
          // Ambiente.configAPI.flagHomologacao = true
          // Ambiente.configAPI.Ambiente = 'Homologação'

        }


      }
    )

  }

  // VERIFICA SE O LOGIN E/OU A SENHA ESTÃO VÁLIDOS E ENVIA-OS PARA O SERVIÇO
  async fazerLogin() {
    
    let valido = true
    this.aparecerSpinner = true;


    if (this.formulario.controls.Login.status == "INVALID") {
      valido = false
      alert("O usuário deve ser informado")
    }

    if (this.formulario.controls.Senha.status == "INVALID") {
      valido = false
      alert('A senha deve ser informada')
    }

    if (valido == true) {

      if (this.formulario.value.Ambiente == 'Produção') {

        this.formulario.value.Ambiente == 'CRMFocus'
        environment.emitir('CRMFocus')

        //LoginComponent.ValorAmbiente.next('https://backendcrm.sistemasfocuscontabil.com/api/')     
        // Ambiente.configAPI.nomeBanco = 'CRMFocus'
        // Ambiente.configAPI.link = 'https://backendcrm.sistemasfocuscontabil.com/api/'
        // Ambiente.configAPI.flagHomologacao = false
        // Ambiente.configAPI.Ambiente = 'Produção'

      }

      else {

        this.formulario.value.Ambiente = 'CRMFocus_HOM'
        environment.emitir('CRMFocus_HOM')

        // LoginComponent.ValorAmbiente.next('https://backendcrm.sistemasfocuscontabil.com/homologacao/api/')
        // Ambiente.configAPI.nomeBanco = 'CRMFocus_HOM'
        // Ambiente.configAPI.link = 'https://backendcrm.sistemasfocuscontabil.com/homologacao/api/'
        // Ambiente.configAPI.flagHomologacao = true
        // Ambiente.configAPI.Ambiente = 'Homologação'

      }

      if (this.formulario.get('ManterConectado').value) {
        this.preencheCookie();
      }
      await this.loginService.fazerLogin(this.formulario.value)
    }
  }

  preencheCookie() {

    this.cookie.deleteAll()

    // let ambiente = this.formulario.get('Ambiente').value + ''
    let ambiente = environment.ambiente

    this.cookie.set("Login", this.formulario.get("Login").value)
    this.cookie.set("Senha", btoa(this.formulario.get("Senha").value))
    this.cookie.set("Ambiente", ambiente)

  }

  mudarAmbiente(evento) {

  }
}

