<mat-card-title style="font-size: 16px;">
  Transferir atendimento
</mat-card-title>

<form [formGroup]="formTroca">
  <div class="row justify-content-center">
    <mat-form-field style="margin-left: 5px;">
      <mat-label>Selecione uma equipe.</mat-label>
      <mat-select formControlName="novaEquipe">
        <mat-option *ngFor="let option of SelectEquipes" [value]="option">
          {{option.nomeEquipe}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row justify-content-end">
    <button mat-raised-button style="line-height: 30px !important;" color="primary"
      (click)="confirmaTroca()">Enviar</button>
  </div>
</form>