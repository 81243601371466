import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NbChatMessageComponent } from '@nebular/theme';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-chat-message-extends',
  templateUrl: './chat-message-extends.component.html',
  styleUrls: ['./chat-message-extends.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageExtendsComponent extends NbChatMessageComponent implements OnInit{

  @Input('customMessage') customMessage: string
  
  @Input('type') type: string;
  @Input('files') files: any[];
  @Input('quote') quote: string;
  @Input('message') message: string;
  @Input('sender') sender: string;
  protected _reply: boolean;

  @Input()
  get reply(): boolean {
    return this._reply;
  }

  set reply(value: boolean) {
    this._reply = value;
  }

  constructor(protected sanitizer: DomSanitizer){
    super(sanitizer);

  }

  ngOnInit(): void {
    
  }
}
