import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import { PaginaErroComponent } from './pagina-erro.component'
import { NbCardModule } from '@nebular/theme';
import { CollapseModule } from 'ngx-bootstrap/collapse';


@NgModule({
  declarations: [
    PaginaErroComponent
  ],
  imports: [
    MatIconModule,
    MatTableModule,
    NbCardModule,
    CommonModule,
    CollapseModule.forRoot(),
  ],
  exports: [
    PaginaErroComponent
  ]
})
export class PaginaErroModule { }
