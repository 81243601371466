import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NumSolicitacaoService {

  private API = environment.API

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  getTabelaNumSolicitacao(){
    return this.http.get<any>(`${this.API}TabelaPesquisa/VWSolicitacao_TabPesquisa`)
  }

  fazPesquisaNumSolicitacao(filtro, componente, campo){

    const httpParams = new HttpParams({
      fromObject: {
        tabela: componente,
        coluna: campo,
        valor: filtro
      }
    })

    return this.http.get<any>(`${this.API}TabelaPesquisa/GetPesquisa`, {params: httpParams})

  }
}
