import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http'
import { map } from 'rxjs/operator/map';
import { tap } from 'rxjs/operators'
import { LoginService } from './login.service';
import { TokenService } from './token.service';
import { environment } from '../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor{

  API = environment.API

  constructor(private http: HttpClient,
              private loginService: LoginService,
              private tokenService: TokenService) {
                environment.emitAPI.subscribe(api => {
                  this.API = api
                })
              }


  tokenEnviado

  intercept(req, next){

    let chamadaHttp = req.clone()   

    if(this.verificaEnderecoHttp(chamadaHttp.url)){

      this.tokenService.getTokenPromise()

      if(chamadaHttp.headers.headers){
        chamadaHttp = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.tokenEnviado}`
          }
        })
      }

    }

    return next.handle(chamadaHttp)
    .pipe(        
      tap(
        (response: HttpEvent<any>) => {
        if (response instanceof HttpResponse) {
          if(response.body.token){
            this.tokenEnviado = response.body.token
          }
        }
        
      })
    )
  }

  public verificaEnderecoHttp(url){

    let resposta = true

    if(url.includes('Relatorio')){
      resposta = false;
    }

    if(url.includes('apiserpro')){
      resposta = false;
    }

    if(url.includes('viacep')){
      resposta = false;
    }

    if(url.includes('servicodados')){
      resposta = false;
    }

    if(url.includes('account/login')){
      resposta = false;
    }

    return resposta;

  }

}
