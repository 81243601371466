import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import { PaginaErroModule } from '../componetes-comum/pagina-erro/pagina-erro.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';

import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    LoginComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FormsModule,
    PaginaErroModule,
    MatSelectModule,
    MatToolbarModule
  ],
  exports: [
    LoginComponent
  ],
  providers: [
    CookieService
  ]
})
export class LoginModule { }
