import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { personalizedIcons } from '../chat/svgDocIcons';
import { NbIconLibraries } from '@nebular/theme';

@Component({
  selector: 'app-historico-conversa',
  templateUrl: './historico-conversa.component.html',
  styleUrls: ['./historico-conversa.component.scss']
})
export class HistoricoConversaComponent implements OnInit {

  public conversa
  public solicitante
  iconesPersonalizados = personalizedIcons

  constructor(@Inject(MAT_DIALOG_DATA) data,
    public iconLibraries: NbIconLibraries,) {
    console.log(data)
    this.conversa = data.conversa
    this.solicitante = data.solicitante
    console.log(this.conversa)

  }

  ngOnInit(): void {
    this.iconLibraries.registerSvgPack('personalized', {
      'pdf': this.iconesPersonalizados.pdf,
      'excel': this.iconesPersonalizados.excel,
      'word': this.iconesPersonalizados.word,
      'audio': this.iconesPersonalizados.audio,
      'image': this.iconesPersonalizados.image,
      'video': this.iconesPersonalizados.video,
      'padrao': this.iconesPersonalizados.padrao,
    })
  }

}
