<form *ngIf="!pesquisaRealizada; else resultadoPesquisa" [formGroup]="formulario">

  <mat-form-field style="width: 100%;">
    <mat-label>Campo</mat-label>
    <mat-select (selectionChange)="MudaTipoCampo($event.value)" formControlName="Campo">
      <mat-option *ngFor="let campo of CamposCombobox" [value]="campo.Coluna">
        {{campo.Campo}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <br>

  <mat-form-field style="width: 100%;">
    <mat-label>Filtro</mat-label>
    <input [type]="TipoDado" formControlName="Filtro" matInput>
    <mat-error *ngIf="formulario.controls.Filtro.hasError('required')">O campo deve ser preencido</mat-error>
  </mat-form-field>

  <br>

  <div class="container-flex">

    <!-- ENVIA PARA A FUNÇÃO PESQUISAR O VALOR DO FITLRO E DO CAMPO SELECIONADO -->
    <button [disabled]="formulario.status == 'VALID' ? false : true" (click)="Pesquisar()" style="width: 100%" 
      class="btn btn-primary btn-block"> Pesquisar
    </button>
  
  </div>

</form>

<br>


<ng-template #resultadoPesquisa>

  <div *ngIf="!carregando">

    <div *ngIf="resultadosPesquisa.length > 0">
    
      <div style="overflow: auto ;max-height: 200px;">
        <table [style.width]="colunas.length > 1 ? '' : '300px' " style="width: 300px" mat-table [dataSource]="resultadosPesquisa" >
    
          <ng-container *ngFor="let coluna of colunas; let colIndex = index" matColumnDef="{{coluna}}">
            <th mat-header-cell *matHeaderCellDef> {{coluna}} </th>
            <td style="max-width: 20px;" [style.font-weight]="coluna == 'Id' ? 700 : ''" mat-cell *matCellDef="let element"> {{element[coluna]}} </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="colunas"></tr>
          <tr mat-row *matRowDef="let row; columns: colunas;" class="example-element-row"
          (click)="EnviaItemTabela(row)"></tr>
        </table>
      </div>

    </div>

    <div *ngIf="resultadosPesquisa.length == 0">
      <p> Sua pesquisa não encontrou nenhum registro </p>
    </div>

    <br>

    <button style="width: 100%;" (click)="VoltarPesquisa()" class="btn btn-primary">Voltar</button>
  
  </div>

  <div *ngIf="carregando" class="spinner-wrapper">
    <mat-spinner [diameter]="100" style="right: 10;"></mat-spinner>
  </div>

</ng-template>

