
<div class="Dialog">
    <button mat-icon-button 
            (click)="fecharDialog()" 
            class="BotaoX">
            <mat-icon>close</mat-icon>
    </button>
</div>

<ng2-pdfjs-viewer #pdfViewer 
                  [downloadFileName]="NomeArquivo" 
                  [openFile]="false" 
                  [find]="false"
                  [viewBookmark]="false"
                  [useOnlyCssZoom]="true"
                  [errorMessage]="MensagemErro"
                  [errorOverride]="true"
                  [fullScreen]="false">
</ng2-pdfjs-viewer>

