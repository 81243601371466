import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription, from } from 'rxjs';
import { ChatService } from '../../../../services/chat.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { EquipeService } from '../../../../services/equipe.service';
import { map, startWith } from 'rxjs/operators';
import { NbWindowConfig, NbWindowRef, NbWindowService, NbWindowState } from '@nebular/theme';
import { ChatComponent } from '../chat/chat.component';
import { environment } from '../../../../environments/environment';
import { LoginService } from '../../../../services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PaginaPerguntaComponent } from '../../componetes-comum/pagina-pergunta/pagina-pergunta.component';
import { DatePipe } from '@angular/common';
import { PaginaErroComponent } from '../../componetes-comum/pagina-erro/pagina-erro.component';
import { PesquisaComponent } from '../../componetes-comum/pesquisa/pesquisa.component';
import { ClienteService } from '../../../../services/cliente.service';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { ContatoEmpresaService } from '../../../../services/contato-empresa.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ISO_8601 } from 'moment';

interface Contato {
  Aniversario: string,
  Email: string,
  Id: number,
  Nome: string,
  PosicaoFuncional: string,
  TelefonePrincipal: string,
  TelefoneSecundario: string,
  idCliente: number
}

@Component({
  selector: 'app-fila-atendimento',
  templateUrl: './fila-atendimento.component.html',
  styleUrls: ['fila-atendimento.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class FilaAtendimentoComponent implements OnInit, AfterViewInit, OnDestroy {

  novoClienteNaFila
  @Input('sideBarStatus') sideBarStatus: Observable<any>
  @Input('EquipeFila') EquipeFila
  dataSourceFila: MatTableDataSource<any>
  colunasFila: any = {
    Descricao: ['Contato', 'Cliente', 'Status'],
    Valor: ['NomeUsuarioWpp', 'nomeCliente', 'Status']
  }

  ColunasFila: any =
    {
      Descricao: ['statusBolinha', 'Contato', 'Cliente', 'Em espera', 'Status'],
      Valor: ['statusBolinha', 'NomeUsuarioWpp', 'nomeCliente', 'Cronometro', 'Status',]
    }
  formFila: FormGroup
  FilaAtendimento: HubConnection
  ArrayFila: any
  chatsAbertos: any[]
  contextStart
  APIChat = environment.Chat
  connection: HubConnection
  intervalId
  Tooltip
  usuarioLogado
  NChatsAbertos: number
  SubChatsAbertos: Subscription
  formularioContato: FormGroup
  ListaContatos
  filtrarContatos
  contatoSelecionado
  showListaContato: boolean = false;
  isClosing: boolean = false;
  showAssunto: boolean = false;
  isClosingAssunto: boolean = false;
  stateFilaSub
  IdEquipe = []

  constructor(public formBuilder: FormBuilder,
    public chatService: ChatService,
    public equipeService: EquipeService,
    public windowService: NbWindowService,
    public loginService: LoginService,
    public _snackBar: MatSnackBar,
    public matDialog: MatDialog,
    public datePipe: DatePipe,
    public clienteService: ClienteService,
    private contatoService: ContatoEmpresaService,
  ) {
    this.formFila = this.formBuilder.group({
      NClientes: [1],
      Departamento: [null]
    })

    this.formularioContato = this.formBuilder.group({
      idCliente: [null],
      Cliente: [null],
      idContato: [null],
      Contato: [null],
      nomeUsuario: [null],
      TelefoneWpp: [null],
      Departamento: [null],
      Assunto: [null]
    })


    this.FilaAtendimento = this.chatService.createConnection(`${this.APIChat}/HubFilaAtendimento`, null)
  }

  ngOnInit(): void {
    this.SubChatsAbertos = this.chatService.chatsAbertos$.subscribe(response => {
      this.NChatsAbertos = response
    })
    this.usuarioLogado = this.loginService.getUsuarioDadosCompletos();
    this.Tooltip = `${this.usuarioLogado.nome}`

    this.filtrarContatos = this.formularioContato.controls.Contato.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          return this._filter(value)
        })
      );


  }

  ngAfterViewInit(): void {
    this.ConectarFila()
    this.intervalId = setInterval(() => {
      this.atualizarData()
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.SubChatsAbertos.unsubscribe();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.ListaContatos.filter(option => option.toLowerCase().includes(filterValue));
  }

  ConectarFila(): void {
    let arrayGroupId = []
    let conexao

    this.chatService.startConnection(this.FilaAtendimento)
      .then((response) => {
        this.EquipeFila.map(equipe => {
          this.IdEquipe.push(equipe.IdEquipeChat)
        })
        if (response == true) {
          this.FilaAtendimento.invoke("AddGroups", this.IdEquipe)
            .then(response => {
            })
        }
        else {
          this._snackBar.open('❌ Não foi possível se conectar a fila de atendimento.', 'Fechar', { duration: 6000 })
        }
      })

    this.FilaAtendimento.on("ReceiveMessage", (message: string) => {
      this.ArrayFila = JSON.parse(message)
      this.atualizaDataSourceFila(this.ArrayFila);
    })

    this.chatService.recebeMensagem(this.FilaAtendimento, 'newMessage')

    this.stateFilaSub = this.FilaAtendimento.onreconnected(() => {

      this.FilaAtendimento.invoke("AddGroups", this.IdEquipe)

    })

  }

  atualizaDataSourceFila(array) {
    array.forEach((a: any) => {
      const matchingDepartamento = this.EquipeFila.find(b => b.IdEquipeChat === a.IdEquipeChat);
      if (matchingDepartamento) {
        a.Departamento = matchingDepartamento.Departamento;
      }
    });
    this.chatService.emitirNumeroClientes(array)
    this.dataSourceFila = new MatTableDataSource(array)
    this.emitirNotificacao()
  }

  emitirNotificacao() {

    if (Notification.permission !== 'granted') {
      Notification.requestPermission()
    }
    else {
      if (this.dataSourceFila.data.length > this.novoClienteNaFila) {
        this.novoClienteNaFila = this.dataSourceFila.data.length
        const notification = new Notification('Novo(s) Cliente(s) na fila de atendimento',
          { body: `${this.dataSourceFila.data[this.dataSourceFila.data.length - 1].NomeUsuarioWpp}` }
        )
      }
      else {
        this.novoClienteNaFila = this.dataSourceFila.data.length
      }
    }
  }

  async AbrirPaginaPergunta(element) {
    if (element.Status != 'Em atendimento') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = "Deseja iniciar o atendimento?"
      this.matDialog.open(PaginaPerguntaComponent, dialogConfig)
        .afterClosed()
        .subscribe(response => {
          if (response) {
            this.iniciarConexaoChat(element)
          }
        })
    }
    else {
      this.iniciarConexaoChat(element)
    }
  }


  iniciarConexaoChat(chatInterface) {

    if (this.NChatsAbertos < 3) {
      if (chatInterface.UsuarioAtendente == this.usuarioLogado.Nome || chatInterface.UsuarioAtendente == null) {
        //ID do chat é igual a PhoneNumberId + Telefone.
        let params = {
          "idUserChat": `${parseInt(chatInterface.Telefone) + parseInt(chatInterface.PhoneNumberIdProd)}`,
        }
        this.connection = this.chatService.createConnection(`${this.APIChat}/HubChat`, params)
        this.chatService.startConnection(this.connection)
          .then(response => {
            if (chatInterface.Status != 'Em atendimento') {
              this.FilaAtendimento.invoke('SetStatusEmAtendimento', this.usuarioLogado.Nome, chatInterface.Telefone, chatInterface.IdChatEquipe)
                .then(response => {
                  if (response == 200) {
                    this.chatService.emitirChatsAbertos(this.NChatsAbertos + 1)
                    // let mensagemInicioPadrao = `Olá ${chatInterface.NomeUsuarioWpp}.\n\nMe chamo ${this.usuarioLogado.Nome}, analista do(a) ${this.EquipeFila[0].Departamento}.\n\nEm que posso ajudar?`
                    // this.connection.send('NewMessageFromChat', this.usuarioLogado.Nome, mensagemInicioPadrao, chatInterface.Telefone, chatInterface.PhoneNumberIdProd, null
                    const windowRef: NbWindowRef = this.windowService.open(ChatComponent, {
                      title: chatInterface.NomeUsuarioWpp.length > 14 ? `${new String(chatInterface.NomeUsuarioWpp).substring(0, 13)}...` : chatInterface.NomeUsuarioWpp,
                      hasBackdrop: false,
                      initialState: NbWindowState.MAXIMIZED,
                      context: {
                        start: chatInterface,
                        equipe: this.EquipeFila,
                        connection: this.connection,
                        connectionFila: this.FilaAtendimento
                      },
                      windowClass: 'chat-window',
                    })

                    windowRef.stateChange.subscribe(response => {
                    })
                    windowRef.onClose.subscribe(response => {
                      this.connection.off('NewMessageFromWhatsapp')
                      this.chatService.emitirChatsAbertos(this.NChatsAbertos - 1)
                    })
                    this.recebeMensagem(this.connection)
                  }
                  if (response == 409) {
                    this.abrirPaginaErro('Este Cliente ja está em atendimento!', 'interno')
                    this.chatService.stopConnection(this.connection, `${parseInt(chatInterface.Telefone) + parseInt(chatInterface.PhoneNumberIdProd)}`)
                  }
                })
            }
            else {
              this.chatService.emitirChatsAbertos(this.NChatsAbertos + 1)
              this.recebeMensagem(this.connection)
              const windowRef: NbWindowRef = this.windowService.open(ChatComponent, {
                title: chatInterface.NomeUsuarioWpp.length > 14 ? `${new String(chatInterface.NomeUsuarioWpp).substring(0, 13)}...` : chatInterface.NomeUsuarioWpp,
                hasBackdrop: false,
                initialState: NbWindowState.MAXIMIZED,
                context: {
                  start: chatInterface,
                  equipe: this.EquipeFila,
                  connection: this.connection,
                  connectionFila: this.FilaAtendimento
                },
                windowClass: 'chat-window',
              })

              windowRef.stateChange.subscribe(response => {
              })
              windowRef.onClose.subscribe(response => {
                this.connection.off('NewMessageFromWhatsapp')
                this.chatService.emitirChatsAbertos(this.NChatsAbertos - 1)
              })

            }
          })
      }
      else {
        this.mensagemEvento(`Este Cliente ja está em atendimento com ${chatInterface.UsuarioAtendente}`, "Fechar")
      }
    }
    else {
      this._snackBar.open('Apenas 3 atendimentos são permitidos de forma simultânea. 🚨', 'Fechar', { duration: 5000 })
    }
  }

  abrirPaginaErro(mensagem, tipo) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      mensagem: mensagem,
      tipo: tipo
    }
    this.matDialog.open(PaginaErroComponent, dialogConfig);
  }

  public mensagemEvento(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 8000,
    });
  }

  recebeMensagem(connection: HubConnection) {

    connection.on('NewMessageFromWhatsapp', (userName: string, message: string, tipoMensagem: string, date: string, nomeArquivo: string) => {
      this.chatService.emitirMessage(
        {
          chatId: connection.baseUrl,
          message: {
            user: {
              name: userName,
              avatar: 'https://cdn-icons-png.flaticon.com/512/6596/6596121.png'
            },
            text: message,
            reply: false,
            Date: date,
            type: tipoMensagem,
            files: null,
            nomeArquivo: nomeArquivo,
          }
        }
      )
    })

  }

  formataTooltip(analista: String) {
    if (analista) {
      return `Sendo atendido por ${analista}`
    }
    else {
      return null
    }
  }

  atualizarData() {
    if (this.dataSourceFila.data) {
      this.dataSourceFila.data.forEach(item => {
        item.Cronometro = this.calcularDiferencaTempo(item.DataHora);
      });
    }
  }

  calcularDiferencaTempo(dataAnterior: string): string {
    let dataAtual = new Date().getTime();
    let dataRecebida = new Date(dataAnterior).getTime();

    let diferenca = Math.abs(dataAtual - dataRecebida);

    const segundos = Math.floor(diferenca / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);

    const segundosRestantes = segundos % 60;
    const minutosRestantes = minutos % 60;

    const horasFormatadas = horas.toString().padStart(2, '0');
    const minutosFormatados = minutosRestantes.toString().padStart(2, '0');
    const segundosFormatados = segundosRestantes.toString().padStart(2, '0');

    return `${horasFormatadas}:${minutosFormatados}:${segundosFormatados}`;
  }

  public BuscarCliente() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      componente: "Cliente",
      condicaoQuery: `Cliente.status <> 'Inativo'`
    };

    this.matDialog
      .open(PesquisaComponent, dialogConfig)
      .afterClosed()
      .subscribe((cliente) => {
        if (cliente) {
          this.formularioContato.patchValue({
            Contato: null,
            nomeUsuario: null,
            Telefone: null,
            Departamento: null
          })
          this.formularioContato.patchValue({
            idCliente: cliente.Id,
            Cliente: cliente.Nome,
          });
          this.contatoService.getContatoChat(cliente.Id)
            .toPromise()
            .then((response: any) => {
              this.filtrarContatos = response
              this.ListaContatos = response
            })
        }
      });
  }

  selecionaContato(contato: MatAutocompleteSelectedEvent) {

    this.contatoSelecionado = contato.option.value

    this.formularioContato.patchValue({
      Contato: `${this.contatoSelecionado.Contato}` + ' - ' + `${this.contatoSelecionado.Telefone}`,
      nomeUsuario: this.usuarioLogado.Nome,
      Telefone: this.contatoSelecionado.TelefoneWpp,
      Departamento: this.EquipeFila[0].Departamento
    })

  }

  padNumber(number: number): string {
    return number.toString().padStart(2, '0');
  }

  limparCampos() {
    this.formularioContato.reset()
    this.filtrarContatos = []
    this.ListaContatos = []
  }

  abrirListaContato() {
    if (this.showListaContato) {
      this.isClosing = true;
      setTimeout(() => {
        this.showListaContato = false;
        this.isClosing = false;
      }, 300); // Tempo de transição
    } else {
      this.showListaContato = true;
    }
  }

  iniciarConversa() {
    this.isClosing = true;
    setTimeout(() => {
      this.showListaContato = false;
      this.isClosing = false;
      this.showAssunto = true
    }, 300); // Tempo de transição
    // this.limparCampos();
  }

  fecharIniciarConversa() {
    this.isClosing = true
    this.showAssunto = false;
    setTimeout(() => {
      this.isClosing = false;
      this.showListaContato = true;
    })
  }

  enviaPrimeiraMensagem() {
    let messageformat = {
      phoneNumberId: this.EquipeFila[0].PhoneNumberID,
      telefone: this.contatoSelecionado.TelefoneWpp,
      nomeCliente: this.contatoSelecionado.Contato,
      nomeUsuario: this.formularioContato.value.nomeUsuario,
      Departamento: this.EquipeFila[0].Departamento,
      Assunto: this.formularioContato.value.Assunto,
      idEquipeChat: this.EquipeFila[0].IdEquipeChat
    }
    this.chatService.enviaPrimeiraMensagem(messageformat)
      .toPromise()
      .then(response => {
        this._snackBar.open('Mensagem enviada, aguardando retorno do cliente. ✅', 'Fechar', { duration: 5000 })
        this.fecharIniciarConversa();
        setTimeout(() => {
          this.showListaContato = false;
          this.isClosing = false;
        }, 300);
        this.formularioContato.patchValue({
          Assunto: '',
        })
      })
  }
}

