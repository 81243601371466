import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArquivoService {

  API = environment.API
  APIRelatorio = environment.APIRelatorio

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  public upload(arquivo){
    return this.http.post(`${this.API}Cliente/uploadcontrato`, arquivo)
  }

  public download(arquivo){
    return this.http.post(`${this.API}Cliente/downloadcontrato`, arquivo)
  }

  public arquivosCliente(idCliente){
    return this.http.get<any[]>(`${this.API}Anexo/GetArquivosCliente/${idCliente}`)
  }




  public downloadBriefingComercial(idCliente): Observable<Blob> {
    return this.http.get(`${this.API}Anexo/GetBriefingComercialCliente/${idCliente}`,  { responseType: 'blob' })
  }

  public downloadBriefingFinanceiro(idCliente): Observable<Blob>{
    return this.http.get(`${this.API}Anexo/GetBriefingFinanceiroCliente/${idCliente}`,  { responseType: 'blob' })
  }

  public downloadContrato(nomeContrato): Observable<any>{
    return this.http.get<any>(`${this.API}Anexo/GetContratoCliente/${nomeContrato}`)
  }



  //#region Métodos geradores de relatórios

  public gerarRelatorio(parametros, relatorio){

    parametros.push(
      {
        Nome: "DataBaseName",
        Valor: environment.ambiente
      }
    )

    switch(relatorio){

      case "briefing financeiro": return this.gerarBriefingFinanceiro(parametros)
      case "briefing comercial": return this.gerarBriefingComercial(parametros)
      case "ata de reunião": return this.gerarAtaReuniao(parametros)
      case "relatorio de agenda": return this.gerarRelatorioAgenda(parametros)
      case "relatorio de solicitacao": return this.gerarRelatorioSolicitacao(parametros)
      case "relatorio de cliente prospect": return this.gerarRelatorioProspect(parametros)
      case "relatorio de pessoa fisica": return this.gerarRelatorioPessoaFisica(parametros)
      case "relatorio de pessoa juridica": return this.gerarRelatorioPessoaJuridica(parametros)
      case "listagem de ata de reuniao": return this.gerarListagemAtaReuniao(parametros)
      case "listagem de briefing comercial": return this.gerarListagemBriefing(parametros)
      case "historico anotacao": return this.gerarHistoricoAnotacao(parametros)
      case "relatorio briefing financeiro": return this.gerarRelatorioBriefingFinanceiro(parametros)
    }

  }

  private gerarBriefingFinanceiro(parametros): Observable<Blob> {
    return this.http.post(`${this.APIRelatorio}RelatorioBriefingFinanceiro`, parametros, { responseType: 'blob' })
  }

  private gerarBriefingComercial(parametros): Observable<Blob> {
    return this.http.post(`${this.APIRelatorio}RelatorioBriefingComercial`, parametros, { responseType: 'blob' })
  }

  private gerarAtaReuniao(parametros): Observable<Blob> {
    return this.http.post(`${this.APIRelatorio}RelatorioAtaReuniao`, parametros, {responseType: 'blob'})
  }

  private gerarRelatorioAgenda(parametros){
    return this.http.post(`${this.APIRelatorio}RelatorioAgenda`, parametros, {responseType: 'blob'})
  }

  private gerarRelatorioSolicitacao(parametros){
    return this.http.post(`${this.APIRelatorio}RelatorioSolicitacao`, parametros, {responseType: 'blob'})
  }

  private gerarRelatorioProspect(parametros){
    return this.http.post(`${this.APIRelatorio}RelatorioClienteProspect`, parametros,  {responseType:'blob'})
  }

  private gerarRelatorioPessoaFisica(parametros){
    return this.http.post(`${this.APIRelatorio}RelatorioClientePessoaFisica`,parametros, {responseType:'blob'})
  }
  private gerarRelatorioPessoaJuridica(parametros){
    return this.http.post(`${this.APIRelatorio}RelatorioClientePessoaJuridica`, parametros, {responseType: 'blob'})
  }
  private gerarListagemAtaReuniao(parametros){
    return this.http.post(`${this.APIRelatorio}RelatorioListagemAtaReuniao`, parametros, {responseType: 'blob'})
  }
  private gerarListagemBriefing(parametros){
    return this.http.post(`${this.APIRelatorio}RelatorioListagemBriefingComercial`, parametros, {responseType: 'blob'})
  }
  private gerarHistoricoAnotacao(parametros){
    return this.http.post(`${this.APIRelatorio}RelatorioHistoricoAnotacao`, parametros, {responseType: 'blob'})
  }
  private gerarRelatorioBriefingFinanceiro(parametros){
    return this.http.post(`${this.APIRelatorio}RelatorioBriefingFinanceiro`, parametros, {responseType: 'blob'})
  }
  //#endregion



}
