import { CookieService } from "ngx-cookie-service";

export class Ambiente {

    static ambiente

    constructor(private cookies: CookieService) {
        Ambiente.ambiente = this.cookies.get('Ambiente')
    }

    static configAPI = {

        production: true,
        //link: 'http://192.168.25.6:8086/api/',
        //link: 'http://192.168.25.6:8091/api/',
       //link:'http://192.168.25.6:8086/api/',
        //link: 'https://backendcrm.sistemasfocuscontabil.com/homologacao/api/',
        link: 'https://backendcrm.sistemasfocuscontabil.com/api/',
        //link: 'https://localhost:44369/api/'

        // ALTERAR AMBIENTE E BOOLEANO AO BUILDAR PARA PUBLICACAO DESEJADA
        flagHomologacao: false,
        Ambiente: 'Produção',
        nomeBanco: 'CRMFocus'
    }



}

    

