
    <div class="row d-flex justify-content-center">
        <p>{{ mensagem }}</p>
    </div>
    <br>
    <div class="row justify-content-end">
        <button mat-raised-button class="mr-2 button-line" color="warn" (click)="Resposta(false)">Não</button>
        <button mat-raised-button class="button-line" color="primary" (click)="Resposta(true)">Sim</button>
    </div>

