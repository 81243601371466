import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AtaReuniaoService {

  API = environment.API

  constructor(private http: HttpClient) {
    environment.emitAPI.subscribe(api => {
      this.API = api
    })
  }

  public getTabelaAtaReuniao() {

    return this.http.get(`${this.API}TabelaPesquisa/VWAtaReuniao`)
  }

  public get(id){
    return this.http.get(`${this.API}AtaReuniao/${id}`)
  }

  public getAtaEvento(idEvento){
    return this.http.get(`${this.API}AtaReuniao/GetAtaReuniaoAgenda/${idEvento}`)
  }

  public getAtaSolicitacao(idAtendimento){
    return this.http.get(`${this.API}AtaReuniao/GetAtaReuniaoSolicitacao/${idAtendimento}`)
  }

  public getUltimaAta(Nome){
    return this.http.get(`${this.API}AtaReuniao/GetUltimaAtaUsuario?Nome=${Nome}`)
  }

  public postPDF(corpo): Observable<Blob> {
    return this.http.post(`${this.API}Relatorio/RelatorioAtaReuniao`, corpo, {responseType: 'blob'})
  }

  public excluir(id){
    return this.http.delete(`${this.API}AtaReuniao/${id}`)
  }

  public post(formulario){

    return this.http.post<any>(`${this.API}AtaReuniao`, formulario)
  }

  public fazPesquisaAtaReuniao(campo, component, filtro) {

    const httpParams = new HttpParams({
      fromObject: {
        tabela: component,
        coluna: filtro,
        valor: campo,
      }
    })

    return this.http.get(`${this.API}TabelaPesquisa/GetPesquisa`, { params: httpParams })
  }

}
