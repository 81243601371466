import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TokenService } from '../../services/token.service';
import { LoginComponent } from '../pages/login/login.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  
  public rota = "";

  constructor(private tokenService: TokenService,
              private router: Router,
              private cookie: CookieService
              ) {

                LoginComponent.ValorAmbiente.subscribe(valor => {

                  if(valor == 'https://backendcrm.sistemasfocuscontabil.com/homologacao/api/'){
                    this.rota = 'homologacao/login'
                  }
            
                  if(valor == 'https://backendcrm.sistemasfocuscontabil.com/api/'){
                    this.rota = '/login'
                  }
            
                })

              }


  // VERIFICA SE HÁ UM TOKEN
  async canActivate(){
  
    let autenticado = await this.tokenService.estaAutenticado()    

    if(!autenticado){
      this.router.navigate([this.rota])
      return false
    }

    return true
  }

  async canLoad(){
    let autenticado = await this.tokenService.estaAutenticado()

    if(!autenticado){
      this.router.navigate([this.rota])
      return false
    }

    return true
  }
}
